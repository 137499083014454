import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Header from "../components/header";
import Footer from "../components/footer";
import { Api, socket, getCurrency } from "../services/webservices";
import { ClipLoader } from "react-spinners";
import $ from "jquery";
import { Modal } from "reactstrap";
import {
  validateMargin,
  validateMintransActionLimit,
  validateMaxtransActionLimit,
  validateRestrictAmount,
  validatetermTrade,
  validateaddTag,
  validatesetPaymentWindow,
} from "../utils/validation";
import locationJson from "../JSON/country.json";
// import currencyJson from "../JSON/currency.json"
import { toast_func } from "../components/toaster";
import "react-toastify/dist/ReactToastify.css";
import { base32 } from "../services/Decrypt";
import { ToastContainer, toast } from "react-toastify";
import { WithContext as ReactTags } from "react-tag-input";
import CreatableSelect from "react-select/lib/Creatable";
// import CKEditor from "ckeditor4-react";
import { CKEditor } from "ckeditor4-react";
import CurrencyModal from "../components/currencyModal";
import renderHTML from "react-render-html";
import ReactTooltip from "react-tooltip";

const KeyCodes = {
  comma: 188,
  enter: 13,
};
const suggestions = [
  { value: "10", label: "10" },
  { value: "15", label: "15" },
  { value: "20", label: "20" },
  { value: "25", label: "25" },
  { value: "50", label: "50" },
  { value: "100", label: "100" },
];

let currencyJson = [];

const delimiters = [KeyCodes.comma, KeyCodes.enter];
export default class EditAdvertise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currencyModal: false,
      priceData: "0",
      profit: 0,
      current: "",
      btnclick: true,
      _id: "",
      loading: false,
      trade_type: "",
      trade_type1: false,
      trade_type2: false,
      trade_typeError: "",
      location: "",
      locationError: "",
      currency: "",
      currencyError: "",
      margin: "",
      marginError: "",
      marginStatus: false,
      minTransactionLimit: "",
      minTransactionLimitError: "",
      minTransactionLimitStatus: false,
      maxTransactionLimit: "",
      maxTransactionLimitError: "",
      maxTransactionLimitStatus: false,
      restrictAmount: "",
      restrictAmountError: "",
      restrictAmountStatus: false,
      termTrade: "",
      termTradeError: "",
      termTradeStatus: false,
      addTag: "",
      addTagError: "",
      addTagStatus: false,
      setPaymentWindow: "",
      setPaymentWindowError: "",
      setPaymentWindowStatus: false,
      btcAmount: "",
      btcAmountError: "",
      identityPeopleChecked: false,
      smsVerificationChecked: false,
      trustedPeopleChecked: false,
      BTCStatus: false,
      BTC: "",
      BTCError: "",
      BTCStatus: false,
      paymentMode: "",
      paymentModeError: "",
      paymentMethod: [],
      paymentMethodS: [],
      userInfo: "",
      bse64data: "",
      bse64data1: "",
      imageArr: [],
      tags: [
        // { id: "Thailand", text: "Thailand" },
        // { id: "India", text: "India" }
      ],
      Amount: "",
      currency1: "USD",
      price: "",
      // suggestions1: [
      //     { id: 'USA', text: 'USA' },
      //     { id: 'Germany', text: 'Germany' },
      //     { id: 'Austria', text: 'Austria' },
      //     { id: 'Costa Rica', text: 'Costa Rica' },
      //     { id: 'Sri Lanka', text: 'Sri Lanka' },
      //     { id: 'Thailand', text: 'Thailand' }
      //  ]
      suggestions: [],
      tagList: {},
      selectedOption: null,
      selectedOption1: null,
      keyPress: false,
      keyTime: 500,
      paymethods: false,
      bank: "",
      _data: "",
    };
    this.getBase64 = this.getBase64.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);
    this.setValue = this.setValue.bind(this);
  }

  fetch = () => {
    socket.emit("currentBTCValue", {
      localCurrency: `${this.state.currency}`,
    });

    socket.on("currentBTCValue", (resp) => {
      this.setState({ current: resp.result.price.toFixed(2) });
    });
  };
  setValue(value) {
    this.setState({ currencyModal: false });
    console.log("Asi==>", value);
    this.setState({ currency: value }, () => {
      this.fetch();
      this.fetchAmount();
    });
  }
  onEditorChange(evt) {
    // console.log("dataaa===>",evt.editor.getData())
    this.setState({
      termTrade: evt.editor.getData(),
    });
  }

  componentDidMount = async () => {
    this.fetch();
    // this.setState({ localCurrency });
    currencyJson = await getCurrency();
    console.log("response from currency============>", currencyJson);
    window.scrollTo(0, 0);
    // $("#e1").select2();
    this.timmer = null;
    var addr = await localStorage.getItem("Address");
    var id = await localStorage.getItem("data");
    var token = await localStorage.getItem("token");
    // console.log("datata...",addr)
    setTimeout(() => {
      if (addr != null) {
        this.setState({ _id: id, token: token, addr: addr }, () =>
          this.stateApis()
        );
      }
    }, 2000);

    this.fetchAmount();
    this.staticContent();
    //  var DecryptData = base32.decode(this.props.match.params._id)

    // this.fetchData(DecryptData);
    setTimeout(() => {
      this.setState({ loading: true });
      // console.log("data------------------",this.state._id);
      Api("user/getUser", "GET", {}, this.state.token, this.state._id).then(
        (resp) => {
          this.setState({ loading: false });
          try {
            if ((resp.status = 200)) {
              if (
                resp.data.responseCode == 409 ||
                resp.data.responseCode == 410 ||
                resp.data.responseCode == 411
              ) {
                localStorage.clear();
                this.props.history.push("/");
              } else if (resp.data.responseCode == 502) {
                this.props.history.push("NotFound");
              }
            } else {
            }
          } catch (ex) {}
        }
      );
    }, 300);
    setTimeout(() => {
      Api("trade/paymentMethodList", "GET", {}).then((resp) => {
        try {
          this.setState({ loading: false });

          if ((resp.status = 200)) {
            if (
              resp.data.responseCode == 409 ||
              resp.data.responseCode == 410 ||
              resp.data.responseCode == 411
            ) {
              localStorage.removeItem("data");
              localStorage.removeItem("isLoggedin");
              localStorage.removeItem("data1");
              localStorage.removeItem("token");
              this.props.history.push("/");
            } else if (resp.data.responseCode == 502) {
              this.props.history.push("NotFound");
            } else if (resp.data.responseCode == 200) {
              this.setState({
                paymentMethod: resp.data.result,
                paymentMethodS: resp.data.result,
              });
            } else toast_func("error", resp.data.responseMessage);
          }
        } catch (ex) {
          toast_func(
            "error",
            "Some problem occured please contact administrator"
          );
        }
      });
    }, 100);
    setTimeout(() => {
      Api("trade/tagList", "GET", {}, this.state.token, this.state._id).then(
        (resp) => {
          try {
            this.setState({ loading: false });

            if ((resp.status = 200)) {
              if (
                resp.data.responseCode == 409 ||
                resp.data.responseCode == 410 ||
                resp.data.responseCode == 411
              ) {
                localStorage.removeItem("data");
                localStorage.removeItem("isLoggedin");
                localStorage.removeItem("data1");
                localStorage.removeItem("token");
                this.props.history.push("/");
              } else if (resp.data.responseCode == 502) {
                this.props.history.push("NotFound");
              } else if (resp.data.responseCode == 200) {
                let arrdata = [];

                if (resp.data.result.length) {
                  for (var i = 0; i < resp.data.result.length; i++) {
                    let data1 = {
                      value: resp.data.result[i].tagName,
                      label: resp.data.result[i].tagName,
                    };
                    // this.state.tagList["id"]=resp.data.result[i].tagName
                    if (resp.data.result[i].tagName !== null) {
                      arrdata.push(data1);
                    }
                  }
                  this.setState({ suggestions: arrdata });
                }
              }
            }
          } catch (ex) {}
        }
      );
    }, 100);
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleOnChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
    if (name == "bank") {
      // console.log("Ritik===>",value)
      // {this.state.paymentMethod.map((item,index)=>{
      //   if(item.name.Find(value)){
      //     console.log("Ritik===>",item.name)
      //   }
      // })}
      var array = this.state.paymentMethod;

      if (value == "") {
        this.setState({ paymentMethodS: array });
      }
      let filterVal = [];
      function search(nameKey, myArray) {
        for (var i = 0; i < myArray.length; i++) {
          if (myArray[i].name.toLowerCase().match(nameKey.toLowerCase())) {
            // return myArray[i];
            filterVal.push(myArray[i]);
          }
        }
      }
      this.setState({ paymentMethodS: filterVal });

      var resultObject = search(value, array);
    }
  }

  setMethod = (e, item) => {
    // console.log("Ritik===>>",item)
    e.preventDefault();
    this.setState({
      bank: item.name,
      paymentId: item._id,
      paymethods: false,
      paymentMode: item.name,
      paymentModeError: "",
    });
  };

  handleChange1 = (selectedOption1) => {
    if (selectedOption1.length !== 0) {
      var check = parseInt(selectedOption1[0].value);
      var check1 = selectedOption1[selectedOption1.length - 1].value.split("");
      for (var i = 0; i < check1.length; i++) {
        if (check1[i] >= 0 && check1[i] <= 9) {
          this.setState({ allow: true });
        } else {
          return;
        }
      }
    } else if (selectedOption1.length == 0) {
      this.setState({ selectedOption1, allow: false });
    }

    //    if(!isNaN(check))
    //    {
    //        alert(typeof(check))
    //    }

    setTimeout(() => {
      if (this.state.allow) {
        if (
          Number(selectedOption1[selectedOption1.length - 1].value) <=
            Number(this.state.maxTransactionLimit) &&
          Number(selectedOption1[selectedOption1.length - 1].value) >=
            Number(this.state.minTransactionLimit)
        ) {
          this.setState({ selectedOption1, allow: false });
        }
      }
    }, 100);
  };

  handleAddition(tag) {
    let index = this.state.suggestions.findIndex((x) => x == tag);
    if (index !== -1) {
      this.setState((state) => ({ tags: [...state.tags, tag] }));
    }
  }
  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
  }
  stateApis = () => {
    this.staticContent();
    return;

    Api(
      `user/deposits_save/${this.state.addr}/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    );
    Api(
      `user/transferToAdminAccount/${this.state.addr}/${this.state._id}`,
      "GET",
      {},
      this.state.token,
      this.state._id
    );
  };
  staticContent = () => {
    var parameter = {
      type: "AddCondition",
    };

    try {
      Api("static/staticContent", "POST", parameter, "", "").then((resp) => {
        if (resp.status == 200) {
          if (resp.data.responseCode == 200) {
            this.setState({ _data: resp.data.succ.description });
          }
        } else {
          toast_func(
            "error",
            "Unable to reach server please contact to administrator"
          );
        }
      });
    } catch (ex) {
      toast_func(
        "error",
        "Unable to reach server please contact administrator"
      );
    }
  };

  onClick_tradeType = (e) => {
    e.preventDefault();
    var value = e.target.value;
    this.setState({ trade_type: value, trade_typeError: "" });
  };
  Populate_location = () => {
    let locationArray = [];
    for (var i = 0; i < locationJson.length; i++) {
      locationArray.push(<option key={i}>{locationJson[i].name}</option>);
    }
    return locationArray;
  };
  onClick_location = (e) => {
    e.preventDefault();
    var value = e.target.value;
    if (value == "Select") {
      this.setState({ location: "", locationError: "" });
    } else this.setState({ location: value, locationError: "" });
  };
  Populate_currency = () => {
    let currencyArray = [];
    for (var i = 0; i < currencyJson.length; i++) {
      currencyArray.push(<option>{currencyJson[i].code}</option>);
    }
    return currencyArray;
  };
  Populate_currency1 = () => {
    let currencyArray = [];
    for (var i = 0; i < currencyJson.length; i++) {
      currencyArray.push(<option key={i}>{currencyJson[i].code}</option>);
    }
    return currencyArray;
  };
  onClick_currency = (e) => {
    e.preventDefault();
    var value = e.target.value;
    this.setState(
      { currency: value, currencyError: "" },
      () => (this.fetchAmount(), this.fetch())
    );
  };
  onClick_currency1 = (e) => {
    e.preventDefault();
    var value = e.target.value;
    this.setState(
      { currency1: value, currencyError: "" },
      () => (this.fetchAmount(), this.fetch())
    );
  };
  fetchAmount() {
    var parameter = {
      localCurrency: this.state.currency,
      margin: this.state.margin,
    };
    if (this.state.currency !== "") {
      this.setState({ loading1: true });
      Api(
        "user/priceEquationWithMargin",
        "POST",
        parameter,
        this.state.token,
        this.state._id
      ).then((resp) => {
        this.setState({ loading1: false });

        if (resp.status === 200) {
          if (
            resp.data.responseCode === 409 ||
            resp.data.responseCode === 410 ||
            resp.data.responseCode === 411
          ) {
            localStorage.removeItem("data");
            localStorage.removeItem("isLoggedin");
            localStorage.removeItem("data1");
            localStorage.removeItem("token");
            toast_func("error", resp.data.responseMessage);
            setTimeout(() => {
              this.props.history.push("/");
            }, 1000);
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          }
          if (resp.data.responseCode === 200) {
            var num = Number(resp.data.result["price"]);
            var n = num.toFixed(2);
            var nn = num.toFixed(2);
            var n1 = n
              .split(".")[0]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            var n2 = n.split(".")[1];
            var price = n1 + "." + n2;

            if (this.state.margin != 0) {
              let timin = parseFloat(this.state.priceData);
              let timout = parseFloat(this.state.current);
              let Tottim = timin - timout;
              console.log("profittttt=====>" + Tottim);
              if (this.state.priceData == "0" || this.state.priceData == "") {
                this.setState({ profit: 0 });
              } else {
                this.setState({
                  priceData: this.state.priceData,
                  profit: Tottim.toFixed(2),
                });
              }
            } else {
              this.setState({ profit: 0 });
            }

            //alert(JSON.stringify(resp.data.result["price"]))
            //this.setState({Amount})

            if (resp.data.result["margin"] == this.state.margin) {
              this.setState({
                price: price,
                price1: resp.data.result["price"],
                priceData: nn,
              });
              // let p = price.replace(/\,/g, '');
              // this.state.priceData;
            } else {
              this.fetchAmount();
            }
          }
        }
      });
    }
  }
  Populate_paymentMethod = () => {
    let paymentArray = [];
    for (var i = 0; i < this.state.paymentMethod.length; i++) {
      paymentArray.push(
        <option key={i}>{this.state.paymentMethod[i].name}</option>
      );
    }

    return paymentArray;
  };
  // fetchData(DecryptData) {
  //     Api(`trade/detail_trade/${DecryptData}`, "GET", {}, this.state.token, this.state._id).then(resp => {

  //         try {
  //             if (resp.status == 200) {
  //                 if (resp.data.responseCode == 200) {
  //                     this.setState({ userInfo: resp.data.result }, () => this.saveData())
  //                 }
  //                 else {
  //                     toast_func("error", resp.data.responseMessage)
  //                 }
  //             }
  //             else {
  //                 toast_func("error", resp.data.responseMessage)
  //             }
  //         }
  //         catch (ex) {
  //             toast_func("error", "Some problem occured please contact administrator")
  //         }

  //     })
  // }
  saveData() {
    if (this.state.userInfo.type_of_trade_original == "sell")
      this.setState({ trade_type2: true });
    else if (this.state.userInfo.type_of_trade_original == "buy")
      this.setState({ trade_type1: true });
    if (this.state.userInfo.location != undefined)
      this.setState({ location: this.state.userInfo.location });
    if (this.state.userInfo.currency_type != undefined)
      this.setState({ currency: this.state.userInfo.currency_type });
    if (this.state.userInfo.margin != undefined)
      this.setState({ margin: this.state.userInfo.margin });
    if (this.state.userInfo.min_transaction_limit != undefined)
      this.setState({
        minTransactionLimit: this.state.userInfo.min_transaction_limit,
      });
    if (this.state.userInfo.max_transaction_limit != undefined)
      this.setState({
        maxTransactionLimit: this.state.userInfo.max_transaction_limit,
      });
    if (this.state.userInfo.restrict_amount != undefined)
      this.setState({ restrictAmount: this.state.userInfo.restrict_amount });
    if (this.state.userInfo.terms_of_trade != undefined)
      this.setState({ termTrade: this.state.userInfo.terms_of_trade });
    if (this.state.userInfo.payment_method != undefined)
      this.setState({ paymentMode: this.state.userInfo.payment_method });
    if (this.state.userInfo.payment_time != undefined)
      this.setState({ setPaymentWindow: this.state.userInfo.payment_time });
    if (this.state.userInfo.sell_Amount != undefined)
      this.setState({ BTC: this.state.userInfo.sell_Amount });
    if (this.state.userInfo.security_options != undefined) {
      this.setState({
        identityPeopleChecked: this.state.userInfo.security_options
          .identfifed_people,
        smsVerificationChecked: this.state.userInfo.security_options
          .sms_verification,
        trustedPeopleChecked: this.state.userInfo.security_options
          .trusted_people,
      });
    }
    if (this.state.userInfo.add_tags != undefined)
      this.setState({ addTag: this.state.userInfo.add_tags });
  }
  onClick_paymentMethod = (e) => {
    e.preventDefault();
    var value = e.target.value;

    var _index = this.state.paymentMethod.findIndex((x) => x.name == value);

    if (value == "Select") {
      this.setState({ paymentMode: "", paymentModeError: "" });
    } else {
      this.setState({
        paymentMode: value,
        paymentModeError: "",
        paymentId: this.state.paymentMethod[_index]._id,
      });
    }
  };
  verify = (type, value) => {
    if (type === "trade_type") {
      if (this.state.trade_type == "") {
        this.setState({ trade_typeError: "*Please select at lease one trade" });
      } else this.setState({ trade_typeError: "" });
    } else if (type === "location") {
      if (this.state.location == "" || this.state.location == "Select") {
        this.setState({ locationError: "*Please select location" });
      } else this.setState({ locationError: "" });
    } else if (type === "currency") {
      if (this.state.currency == "" || this.state.currency == "Select") {
        this.setState({ currencyError: "*Please select currency" });
      } else this.setState({ currencyError: "" });
    }

    // else if (type === 'margin') {
    //     this.fetchAmount();
    //     if (validateMargin(this.state.margin).status) {
    //         this.setState({ marginError: "", marginStatus: true }, () => this.fetchAmount())
    //     } else this.setState({ marginError: validateMargin(this.state.margin).error, marginStatus: false })
    // }
    else if (type === "minTransactionLimit") {
      if (validateMintransActionLimit(this.state.minTransactionLimit).status) {
        this.setState({
          minTransactionLimitError: "",
          minTransactionLimitStatus: true,
        });
      } else
        this.setState({
          minTransactionLimitError: validateMintransActionLimit(
            this.state.minTransactionLimit
          ).error,
          minTransactionLimitStatus: false,
        });
    } else if (type === "maxTransactionLimit") {
      if (validateMaxtransActionLimit(this.state.maxTransactionLimit).status) {
        this.setState({
          maxTransactionLimitError: "",
          maxTransactionLimitStatus: true,
        });
      } else
        this.setState({
          maxTransactionLimitError: validateMaxtransActionLimit(
            this.state.maxTransactionLimit
          ).error,
          maxTransactionLimitStatus: false,
        });
    }
    // else if (type === 'restrictAmount') {
    //     if (validateRestrictAmount(this.state.restrictAmount).status) {
    //         this.setState({ restrictAmountError: "", restrictAmountStatus: true })
    //     } else this.setState({ restrictAmountError: validateRestrictAmount(this.state.restrictAmount).error, restrictAmountStatus: false })
    // }
    else if (type === "termTrade") {
      if (validatetermTrade(this.state.termTrade).status) {
        this.setState({ termTradeError: "", termTradeStatus: true });
      } else
        this.setState({
          termTradeError: validatetermTrade(this.state.termTrade).error,
          termTradeStatus: false,
        });
    } else if (type === "addTag") {
      if (validateaddTag(this.state.addTag).status) {
        this.setState({ addTagError: "", addTagStatus: true });
      } else
        this.setState({
          addTagError: validateaddTag(this.state.addTag).error,
          addTagStatus: false,
        });
    }
    // else if (type === 'BTC') {
    //     if (this.state.BTC !== "") {
    //         this.setState({ BTCError: "", BTCStatus: true })
    //     } else this.setState({ BTCError: "*Please enter valid BTC value", BTCStatus: false })
    // }
    else if (type === "paymentMode") {
      if (this.state.paymentMode == "" || this.state.paymentMode == "Select") {
        this.setState({ paymentModeError: "*Please select Payment method." });
      } else this.setState({ paymentModeError: "" });
    } else if (type === "setPaymentWindow") {
      if (validatesetPaymentWindow(this.state.setPaymentWindow).status) {
        this.setState({
          setPaymentWindowError: "",
          setPaymentWindowStatus: true,
        });
      } else
        this.setState({
          setPaymentWindowError: validatesetPaymentWindow(
            this.state.setPaymentWindow
          ).error,
          setPaymentWindowStatus: false,
        });
    }
  };
  handleUserInput = (e) => {
    e.preventDefault();
    let type = e.target.name;
    let value = e.target.value;

    if (type == "trade_type") {
      if (isNaN(value) != true) {
        this.setState({ [type]: value }, () => {
          this.verify(type, value);
        });
      }
    } else if (type == "location") {
      if (isNaN(value) != true) {
        this.setState({ [type]: value }, () => {
          this.verify(type, value);
        });
      }
    }
    // else if (type == 'BTC') {
    //     //alert(">"+value+"<")
    //     if (!isNaN(value) && (value !== " ")) {
    //         this.setState({ [type]: value }, () => {
    //             this.verify(type, value)
    //         })
    //     }
    // }
    else if (type == "currency") {
      if (isNaN(value) != true) {
        this.setState({ [type]: value }, () => {
          this.verify(type, value);
        });
      }
    } else if (type == "margin") {
      // if (!isNaN(value) && (value != " ")) {
      //             if (value < 1000) {
      //                 this.setState({ [type]: value }, () => {
      //                     this.verify(type, value)
      //                 this.fetchAmount()
      //                 })
      //         }

      //     }
      if ((!isNaN(value) || value == "." || value == "-") && value != " ") {
        if (value <= 10000 || value == "-") {
          var val = value.split("");
          if (val[0] == "-") {
            if (value * -1 <= 10000 || value == "-") {
              clearTimeout(this.timer);
              this.setState(
                {
                  [type]: value,
                },
                () => {
                  this.verify(type, value);
                  this.fetchAmount();
                }
              );

              // socket.emit('currentBTCValue', {
              //     amount:200,
              //     pricePerBtc:200
              //  })
            }
          } else {
            clearTimeout(this.timer);
            this.setState({ [type]: value }, () => {
              this.verify(type, value);
              this.fetchAmount();
            });
          }

          setTimeout(() => {
            let timin = parseFloat(this.state.priceData);
            let timout = parseFloat(this.state.current);
            let Tottim = timin - timout;

            if (this.state.priceData == "0" || this.state.priceData == "")
              this.setState({ profit: 0 });
            else
              this.setState({
                priceData: this.state.priceData,
                profit: Tottim.toFixed(2),
              });
          }, 1000);
        } else if (value == "") {
          this.setState({ [type]: 0, profit: 0 }, () => this.fetchAmount());
        }
      } else {
        this.setState({ [type]: 0, profit: 0 }, () => this.fetchAmount());
      }
    } else if (type == "minTransactionLimit") {
      if (!isNaN(value) && value != " ") {
        this.setState({ [type]: value }, () => {
          this.handleChange1([]);
          if (Number(this.state.minTransactionLimit) <= 0) {
            this.setState({
              minTransactionLimitError:
                "Please enter min. limit greater than zero",
            });
            return;
          } else if (
            Number(this.state.maxTransactionLimit) <
            Number(this.state.minTransactionLimit)
          ) {
            this.setState({
              minTransactionLimitError: "",
              maxTransactionLimitError:
                "Max. transaction limit should always be greater than or equal to Min. transaction limit",
            });
          } else {
            this.setState({
              minTransactionLimitError: "",
              maxTransactionLimitError: "",
            });
            this.verify(type, value);
          }

          socket.emit("amountToBtc", {
            amount: Number(this.state.currency),
            pricePerBtc: Number(this.state.price1),
          });
        });
      }
    } else if (type == "maxTransactionLimit") {
      if (!isNaN(value) && value !== " ") {
        if (this.state.minTransactionLimit !== "") {
          this.setState({ [type]: value }, () => {
            this.handleChange1([]);
            if (parseFloat(this.state.maxTransactionLimit) <= 0) {
              this.setState({
                maxTransactionLimitError:
                  "Please enter max. limit greater than zero",
              });
              return;
            }
            if (
              Number(this.state.maxTransactionLimit) <
              Number(this.state.minTransactionLimit)
            ) {
              this.setState({
                maxTransactionLimitError:
                  "Max. transaction limit should always be greater than or equal to Min. transaction limit",
                minTransactionLimitError: "",
              });
            } else {
              this.setState({
                minTransactionLimitError: "",
                maxTransactionLimitError: "",
              });
              this.verify(type, value);
            }
          });
        } else {
          this.setState({
            maxTransactionLimitError:
              "Please enter the Min. transaction limit first",
          });
        }
      }
    } else if (type == "restrictAmount") {
      if (!isNaN(value) && value !== " ") {
        this.setState({ [type]: value }, () => {
          this.verify(type, value);
        });
      }
    } else if (type == "termTrade") {
      var value1 = value.trim;
      if (value[0] != " ") {
        this.setState({ [type]: value }, () => {
          this.verify(type, value);
        });
      }
    }

    // else if (type == 'addTag') {

    //     this.setState({ [type]: value }, () => {
    //         this.verify(type, value)
    //     })

    // }
    else if (type == "paymentMode") {
      if (isNaN(value) != true) {
        this.setState({ [type]: value }, () => {
          this.verify(type, value);
        });
      }
    } else if (type == "setPaymentWindow") {
      if (!isNaN(value) && value != " ") {
        if (value <= 360) {
          this.setState({ [type]: value }, () => {
            this.verify(type, value);
          });
        }
      }
    }
  };
  printChange = () => {
    alert(this.state.margin);
  };

  publish_Advertise = (e) => {
    e.preventDefault();

    if (!this.state.trade_type1 && !this.state.trade_type2) {
      this.setState({ trade_typeError: "*Please select at least one trade" });
    } else {
      this.setState({ trade_typeError: "" });
    }

    if (this.state.location == "" || this.state.location == "Select") {
      this.setState({ locationError: "*Please select location" });
    } else {
      this.setState({ locationError: "" });
    }

    // if ((this.state.currency == "") || (this.state.currency == "Select")) {
    //     this.setState({ currencyError: "*Please select currency" })
    // }
    // else {
    //     this.setState({ currencyError: "" })
    // }

    // if (validateMargin(this.state.margin).status == true) {
    //     this.setState({ marginStatus: true, marginError: "" })
    // }
    // else {
    //     this.setState({ marginError: validateMargin(this.state.margin).error, marginStatus: false })
    // }

    if (
      validateMintransActionLimit(this.state.minTransactionLimit).status == true
    ) {
      this.setState({
        minTransactionLimitStatus: true,
        minTransactionLimitError: "",
      });
    } else {
      this.setState({
        minTransactionLimitError: validateMintransActionLimit(
          this.state.minTransactionLimit
        ).error,
        minTransactionLimitStatus: false,
      });
    }

    if (validateMaxtransActionLimit(this.state.maxTransactionLimit).status) {
      if (
        parseInt(this.state.minTransactionLimit) >
        parseInt(this.state.maxTransactionLimit)
      ) {
        this.setState({
          maxTransactionLimitStatus: false,
          maxTransactionLimitError:
            "*Please enter the max. limit greater than min. limit",
        });
      } else
        this.setState({
          maxTransactionLimitStatus: true,
          maxTransactionLimitError: "",
        });
    } else {
      this.setState({
        maxTransactionLimitError: validateMaxtransActionLimit(
          this.state.maxTransactionLimit
        ).error,
        maxTransactionLimitStatus: false,
      });
    }

    // if (validateRestrictAmount(this.state.restrictAmount).status == true) {
    //     this.setState({ restrictAmountStatus: true, restrictAmountError: "" })
    // }
    // else {
    //     this.setState({ restrictAmountError: validateRestrictAmount(this.state.restrictAmount).error, restrictAmountStatus: false })
    // }

    if (validatetermTrade(this.state.termTrade).status == true) {
      this.setState({ termTradeStatus: true, termTradeError: "" });
    } else {
      this.setState({
        termTradeError: validatetermTrade(this.state.termTrade).error,
        termTradeStatus: false,
      });
    }

    // if (validateaddTag(this.state.addTag).status == true) {
    //     this.setState({ addTagStatus: true, addTagError: "" })
    // }
    // else {
    //     this.setState({ addTagError: validateaddTag(this.state.addTag).error, addTagStatus: false })
    // }
    if (this.state.paymentMode == "" || this.state.paymentMode == "Select") {
      this.setState({ paymentModeError: "*Please select payment method" });
    } else {
      this.setState({ paymentModeError: "" });
    }
    // if (this.state.BTC == "") {
    //     this.setState({ BTCError: "*Please enter BTC" })
    // }
    // else {
    //     this.setState({ BTCError: "" })
    // }
    if (validatesetPaymentWindow(this.state.setPaymentWindow).status == true) {
      this.setState({
        setPaymentWindowStatus: true,
        setPaymentWindowError: "",
      });
    } else {
      this.setState({
        setPaymentWindowError: validatesetPaymentWindow(
          this.state.setPaymentWindow
        ).error,
        setPaymentWindowStatus: false,
      });
    }

    let arr = [];
    if (this.state.selectedOption) {
      for (var i = 0; i < this.state.selectedOption.length; i++) {
        arr.push(this.state.selectedOption[i]["label"]);
      }
    }
    let arr1 = [];
    if (this.state.selectedOption1) {
      for (var i = 0; i < this.state.selectedOption1.length; i++) {
        arr1.push(this.state.selectedOption1[i]["label"]);
      }
    }
    setTimeout(() => {
      if (
        this.state.trade_typeError == "" &&
        this.state.locationError == "" &&
        this.state.currencyError == "" &&
        this.state.minTransactionLimitError == "" &&
        this.state.maxTransactionLimitError == "" &&
        this.state.termTradeError == "" &&
        this.state.addTagError == "" &&
        this.state.paymentModeError == "" &&
        this.state.setPaymentWindowError == ""
      ) {
        this.create_Advertise(arr, arr1);
      }
    }, 200);
  };
  verify1 = (e) => {
    e.preventDefault();
    let type = e.target.name;
    let value = e.target.value;

    //this.setState({ [type]: value });
    setTimeout(() => {
      if (type == "file_data4") {
        let _file_data = $(`#file_data4`).prop("files")[0];
        this.getBase64(_file_data, "file_data4");
        // let fileName = value.split(/(\\|\/)/g).pop();

        this.setState({ [type]: value });
        //     alert("@@@@@===="+this.state.file_data4)
        //   let array=this.state.imageArr
        //   array.push({img:this.state.file_data4})
        //   console.log("1111==="+JSON.stringify(array))
      }
    }, 200);
  };
  getBase64(file, type) {
    let self = this;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      let bse64data1 = reader.result.substr(reader.result).split(",")[0];
      let bse64data = reader.result.substr(reader.result.indexOf(",") + 1);
      let full_image = bse64data1 + bse64data;
      if (type == "file_data4") {
        self.setState({
          getBase64Data: bse64data,
          files4: bse64data1 + "," + bse64data,
          img4: full_image,
        });
        // self.setState({ files4: bse64data1 + "," + bse64data, img4: full_image })

        if (self.state.files4) {
          let array = self.state.imageArr;

          var parameter = {
            image: self.state.files4,
          };
          Api("trade/uploadProductImage", "POST", parameter).then((resp) => {
            // this.setState({ loading: false })

            try {
              //   console.log("1111==="+JSON.stringify(resp))
              if ((resp.status = 200)) {
                if (resp.data.responseCode == 200) {
                  array.push({ imageUrl: resp.data.data });
                  // console.log("1111==="+JSON.stringify(array))
                  self.setState({ imageArr: array });
                } else if (resp.data.responseCode == 502) {
                  this.props.history.push("NotFound");
                } else toast_func("error", resp.data.responseMessage);
              } else toast_func("error", resp.data.responseMessage);
            } catch (ex) {
              //toast_func("error", "Some problem occured please contact administrator")
            }
          });
        }
      }
      // alert("####====" + self.state.files4)

      //self.setState({ file_dataError: '' })
    };
    reader.onerror = function(error) {
      // display toaster
    };
  }
  removeImg(e, ind) {
    e.preventDefault();
    let arr = this.state.imageArr;
    arr.splice(ind, 1);
    this.setState({ imageArr: arr });
    console.log("rem====>" + JSON.stringify(arr));
  }
  create_Advertise(arr, arr1) {
    // alert(">"+this.state.trade_type1+"<"+this.state.trade_type2)
    //return
    var parameter = {
      userId: this.state._id,
      //"method_of_payment": this.state.paymentMode,
      paymentMethodId: this.state.paymentId,
      ruleAndrequirement: this.state.trade_type1
        ? "You can only buy post"
        : "You can only sell post",
      trade_type: this.state.trade_type1 ? "sell" : "buy",
      location: this.state.location,
      currencyType: this.state.currency,
      margin: this.state.margin == "" ? 0 : this.state.margin,
      priceEquation: this.state.price1,
      minTxnlimit: this.state.minTransactionLimit,
      maxTxnlimit: this.state.maxTransactionLimit,
      restrictAmount: arr1,
      termTrade: this.state.termTrade,
      addTag: arr,
      // "btcAmount": this.state.BTC,
      payment_time: this.state.setPaymentWindow, //1 ,this.state.setPaymentWindow
      sms_verification: JSON.stringify(this.state.smsVerificationChecked),
      trusted_people: JSON.stringify(this.state.trustedPeopleChecked),
      identfifed_people: JSON.stringify(this.state.identityPeopleChecked),
      product_images: this.state.imageArr,
    };
    this.setState({ loading: true, btnclick: false });
    Api(
      "trade/create_trade_advertise",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      try {
        if ((resp.status = 200)) {
          if (
            resp.data.responseCode == 409 ||
            resp.data.responseCode == 410 ||
            resp.data.responseCode == 411
          ) {
            localStorage.removeItem("data");
            localStorage.removeItem("data1");
            localStorage.removeItem("token");
            localStorage.removeItem("isLoggedin");
            this.props.history.push("/");
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else if (resp.data.responseCode == 200) {
            this.setState({ loading: false });
            toast_func("success", resp.data.responseMessage);

            setTimeout(() => {
              this.props.history.push("/dashboard");
            }, 2500);
          } else {
            toast_func("error", resp.data.responseMessage);
          }
        } else {
          toast_func(
            "error",
            "Some problem occured please contact administrator"
          );
        }
      } catch (ex) {
        toast_func(
          "error",
          "Some problem occured please contact administrator"
        );
      }
    });
  }

  refinePrice(amount) {
    var num = Number(amount);
    var n = num.toFixed(2);
    var nn = num.toFixed(2);
    var n1 = n
      .split(".")[0]
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    var n2 = n.split(".")[1];
    var price = n1 + "." + n2;
    return price;
  }

  handleCallback = (val) => {
    if (val === "true") {
      document.body.style.setProperty("--primary-color", "#000");
      document.body.style.setProperty("--black-color", "#fff");
      document.body.style.setProperty("--white-color", "#000");
      document.body.style.setProperty("--text-grey", "#ffffffd4");
      document.body.style.setProperty("--table-hover", "#000000e3");
      document.body.style.setProperty("--popUpBW-color", "#fff");
    } else {
      document.body.style.setProperty("--popUpBW-color", "#3e5c85");
      document.body.style.setProperty("--primary-color", "#3e5c85");
      document.body.style.setProperty("--black-color", "#000");
      document.body.style.setProperty("--white-color", "#fff");
      document.body.style.setProperty("--text-grey", "#3a3a3a");
      document.body.style.setProperty("--table-hover", "#80808026");
    }
  };

  render() {
    var isloggedIn = localStorage.getItem("isLoggedin");
    return (
      <div>
        {this.state.loading ? (
          <div className="loader">
            {" "}
            <img
              className=""
              style={{ height: 100, width: 100 }}
              src={require("../assets/lg.gif")}
            />{" "}
          </div>
        ) : null}
        <Header {...this.props} parentCallback1={this.handleCallback} />
        <ToastContainer />

        <main className="mainContent">
          <section className="tradeAdBg">
            <div className="container1440 max-WT-1200">
              <div className="buySellBox">
                <h2 className="header2">Create Bitcoin Trade Advertisement</h2>
              </div>
            </div>
          </section>
          {/* <div className="border radius4 p15 mb15">
                                <img src={require("../assets/images/danger-icon.png")} className="mr7" />Please <Link to={"/login"}><a className="underline">LOGIN</a></Link> OR <Link to={"/signup"}><a className="underline">REGISTER</a></Link> before creating an advertisement.
                  </div> */}

          <section className="">
            <div className="container1440 max-WT-1200">
              <form className="mb0 createAdv">
                {/************ trade terms *******************/}
                <div className="sectionHead mb15">
                  <h2 className="font24 textInitial weight400">
                    Advertisement Rules And Requirements
                  </h2>
                  {this.state._data == "" ? (
                    <p className="text-center wColor font18">Loading.....</p>
                  ) : (
                    <ul className="detail-list mt10" style={{ color: "white" }}>
                      {this.state._data != undefined
                        ? renderHTML(`${this.state._data}`)
                        : null}
                    </ul>
                  )}
                </div>

                <div className="row">
                  <div className="col-md-10 marAuto">
                    <div className="cardBox mt40">
                      <div className="formBox">
                        <div className="sectionHead mb15">
                          <h2 className="font18 textInitial fontfamTB">
                            Advertisement Rules And Requirements
                          </h2>
                        </div>

                        <div className="formInnerNew">
                          <div className="row">
                            <div className="col-md-8">
                              <label className="control-label">
                                I want to{" "}
                              </label>
                              <div className="d-flex">
                                <div className="radio-design-block mb15">
                                  <label className="radio-design  largeRadio mb0 font14 fontfamQR">
                                    <input
                                      value={this.state.trade_type1}
                                      type="radio"
                                      name="trade_type"
                                      checked={`${
                                        this.state.trade_type1 ? "checked" : ""
                                      }`}
                                      onChange={() =>
                                        this.setState({
                                          trade_type1: !this.state.trade_type1,
                                          trade_type2: false,
                                          trade_typeError: "",
                                        })
                                      }
                                    />
                                    <span />
                                    Sell Bitcoin
                                  </label>
                                  <span style={{ marginLeft: 5, fontSize: 15 }}>
                                    <i
                                      className="fas fa-info-circle"
                                      data-tip="Your offer will be shown on the Buy Bitcoin page."
                                    />
                                    <ReactTooltip />
                                  </span>
                                </div>
                                <div
                                  className="radio-design-block"
                                  style={{ marginLeft: 20 }}
                                >
                                  <label className="radio-design largeRadio mb0 font14 fontfamQR">
                                    <input
                                      value={this.state.trade_type2}
                                      type="radio"
                                      name="trade_type"
                                      checked={`${
                                        this.state.trade_type2 ? "checked" : ""
                                      }`}
                                      onChange={() =>
                                        this.setState({
                                          trade_type2: !this.state.trade_type2,
                                          trade_type1: false,
                                          trade_typeError: "",
                                        })
                                      }
                                    />
                                    <span />
                                    Buy Bitcoin
                                  </label>
                                  <span style={{ marginLeft: 5, fontSize: 15 }}>
                                    <i
                                      className="fas fa-info-circle"
                                      data-for="tt"
                                      data-tip="Your offer will be shown on the Sell Bitcoin page."
                                    />
                                    <ReactTooltip
                                      className={"overrideMe"}
                                      data-html={true}
                                      insecure={true}
                                      multiline={true}
                                      id="tt"
                                    />
                                  </span>
                                  {/* <span style={{ marginLeft: 5, fontSize: 15 }} >
                                                                        <p title=" Your offer will be shown on the Sell Bitcoin page" ></p>
                                                                        <i className="fas fa-info-circle customabbr" ></i>
                                                                    </span> */}
                                  {this.state.trade_typeError != "" ? (
                                    <label className="error">
                                      {this.state.trade_typeError}
                                    </label>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <label className="control-label">Location</label>
                              <select
                                name="location"
                                value={this.state.location}
                                onChange={(e) => this.onClick_location(e)}
                                className="form-control select-style1"
                              >
                                <option>Select</option>
                                {this.Populate_location()}
                              </select>
                              {this.state.locationError != "" ? (
                                <label className="error">
                                  {this.state.locationError}
                                </label>
                              ) : null}
                            </div>
                          </div>
                          {/* <div className="form-group row align-items-center mb0">
                                                        <label className="col-md-4 control-label">Location</label>
                                                       
                                                        <div className="col-md-8">
                                                            <select name="location" value={this.state.location} onChange={(e) => this.onClick_location(e)} className="form-control select-style1">
                                                                <option>Select</option>
                                                                {this.Populate_location()}
                                                            </select>
                                                            {this.state.locationError != "" ?
                                                                <label className="error">{this.state.locationError}</label>
                                                                : null}
                                                        </div>
                                                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 marAuto">
                    <div className="cardBox  mt40">
                      <div className="formBox">
                        <div className="sectionHead">
                          <h2 className="font18 textInitial fontfamTB">
                            Security Options
                          </h2>
                        </div>
                        <div className="formInnerNew d-flex">
                          <div className="form-group row mb7 align-items-center">
                            <div className="col-2 col-md-2">
                              <div className="checkbox-design-block">
                                <label className="checkbox-design font16">
                                  <input
                                    name="identityPeopleChecked"
                                    value={this.state.identityPeopleChecked}
                                    onChange={() =>
                                      this.setState({
                                        identityPeopleChecked: !this.state
                                          .identityPeopleChecked,
                                      })
                                    }
                                    checked={`${
                                      this.state.identityPeopleChecked
                                        ? "checked"
                                        : ""
                                    }`}
                                    type="checkbox"
                                  />
                                  <span />
                                </label>
                              </div>
                            </div>
                            <label className="col-10 col-md-10 labelStyle fontfamQR">
                              Identity verification needed
                            </label>
                          </div>
                          <div className="form-group row mb7 align-items-center">
                            <div className="col-2 col-md-2">
                              <div className="checkbox-design-block">
                                <label className="checkbox-design font16">
                                  <input
                                    name="smsVerificationChecked"
                                    value={this.state.smsVerificationChecked}
                                    onChange={() =>
                                      this.setState({
                                        smsVerificationChecked: !this.state
                                          .smsVerificationChecked,
                                      })
                                    }
                                    checked={`${
                                      this.state.smsVerificationChecked
                                        ? "checked"
                                        : ""
                                    }`}
                                    type="checkbox"
                                  />
                                  <span />
                                </label>
                              </div>
                            </div>
                            <label className="col-10 col-md-10 labelStyle fontfamQR">
                              SMS verification needed
                            </label>
                          </div>
                          <div className="form-group row mb7 align-items-center">
                            <div className="col-2 col-md-2">
                              <div className="checkbox-design-block">
                                <label className="checkbox-design font16">
                                  <input
                                    name="trustedPeopleChecked"
                                    value={this.state.trustedPeopleChecked}
                                    onChange={() =>
                                      this.setState({
                                        trustedPeopleChecked: !this.state
                                          .trustedPeopleChecked,
                                      })
                                    }
                                    checked={`${
                                      this.state.trustedPeopleChecked
                                        ? "checked"
                                        : ""
                                    }`}
                                    type="checkbox"
                                  />
                                  <span />
                                </label>
                              </div>
                            </div>
                            <label className="labelStyle fontfamQR col-10 col-md-10">
                              Only people who Trust my Profile
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 marAuto">
                    <div className="cardBox mt40">
                      <div className="formBox">
                        <div className="sectionHead">
                          <h2 className="font18 textInitial fontfamTB">
                            More Information
                          </h2>
                        </div>
                        <div className="formInnerNew">
                          <div className="form-group row">
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-12">
                                  <label className="control-label">
                                    Payment Method
                                  </label>
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      margin: 5,
                                      fontSize: 15,
                                      width: 200,
                                    }}
                                    data-for="tt"
                                    data-tip="Eg. 1. If you want  to trade any product or service like gift card, select the gift card here and in currency select USD, CAD etc from fiat in currency tab.
                                                                         <br />2. If you want to trade any altcoin select cryptocurrency or altcoin here and select the crypto from the currency tab."
                                  >
                                    <i className="fas fa-info-circle" />
                                    <ReactTooltip
                                      className={"overrideMe"}
                                      data-html={true}
                                      insecure={true}
                                      multiline={true}
                                      id="tt"
                                    />
                                  </span>
                                  <input
                                    type="text"
                                    name="bank"
                                    value={this.state.bank}
                                    readOnly
                                    placeholder="Select Payment Method"
                                    onClick={() =>
                                      this.setState({ paymethods: true })
                                    }
                                    className="form-control select-style1"
                                  >
                                    {/* {this.Populate_paymentMethod()} */}
                                  </input>

                                  {this.state.paymentModeError != "" ? (
                                    <label className="error">
                                      {this.state.paymentModeError}
                                    </label>
                                  ) : null}
                                </div>
                                <Modal
                                  isOpen={this.state.paymethods}
                                  fade={false}
                                  style={{
                                    alignSelf: "center",
                                    justifyContent: "center",
                                    margintop: "10%",
                                    maxWidth: "950px",
                                    margintop: "60px",
                                  }}
                                >
                                  <div className="cardBox max-WT-950 center-box">
                                    <div className="cardHead center-box pb10 text-center posRel">
                                      <span
                                        className="crossBtn"
                                        onClick={() =>
                                          this.setState({ paymethods: false })
                                        }
                                      >
                                        <i className="fa fa-times" />
                                      </span>

                                      <h4 className="font18 mt0 mb5 text-center">
                                        Method
                                      </h4>
                                    </div>
                                    {/* <p className="rcvCoin pl15 pr15">if you setup 2 step verification, you can use the google authenticator app to receive codes even if you don't have an internet connection or mobile service for trades & transactions</p> */}
                                    <form
                                      className="mb0"
                                      style={{
                                        height: "350px",
                                        overflowY: "auto",
                                        backgroundColor: "var(--white-color)",
                                      }}
                                    >
                                      <div className="formBox  max-WT-750 center-box">
                                        {/* <div className="form-group">
                                                                        <label className="control-label">Authenticate code</label>
                                                                        <input type="text" className="form-control" name="auth_code" id="auth_cade" maxLength="6" />
                                                                    </div> */}
                                        <div className="form-group mt20">
                                          <div className="input-per2">
                                            <input
                                              type="text"
                                              maxLength="15"
                                              className="form-control modalSearch"
                                              placeholder="All Online offers"
                                              name="bank"
                                              value={this.state.bank}
                                              onChange={(e) =>
                                                this.handleOnChange(e)
                                              }
                                            />
                                            <span>
                                              <i className="fa fa-search" />
                                            </span>
                                          </div>
                                        </div>
                                        <div className="form-group">
                                          <div className="row">
                                            {this.state.paymentMethodS.map(
                                              (item, index) => {
                                                return (
                                                  <div
                                                    className="col-md-4"
                                                    key={index}
                                                  >
                                                    <button
                                                      className="paymethod"
                                                      title={item.name}
                                                      onClick={(e) =>
                                                        this.setMethod(e, item)
                                                      }
                                                    >
                                                      <img
                                                        src={require("../assets/newImages/bitIcon.png")}
                                                        className=""
                                                      />
                                                      {item.name.length < 15
                                                        ? item.name
                                                        : item.name.slice(
                                                            0,
                                                            14
                                                          ) + ".."}
                                                    </button>
                                                  </div>
                                                );
                                              }
                                            )}

                                            {/* <div className="col-md-4"> <button className="paymethod"><i className="fa fa-home"></i>Paytm Online Wallet</button></div>
                                                                        <div className="col-md-4"> <button className="paymethod"><i className="fa fa-home"></i> Google Pay</button></div> */}
                                          </div>
                                          {/* <div className="row mt10">
                                                                        <div className="col-md-4">
                                                                            <button className="paymethod"><i className="fa fa-home"></i>Game Items</button>
                                                                        </div>
                                                                        <div className="col-md-4"> <button className="paymethod"><i className="fa fa-home"></i>PhonePe</button></div>
                                                                        <div className="col-md-4"> <button className="paymethod"><i className="fa fa-home"></i> Paypal</button></div>
                                                                        </div> */}
                                        </div>

                                        {/* <div className='text-center'>
                                                                    <button type="submit" className="btn btnGreen w-100 radius4 mb20">Verify</button>
                                                                    </div> */}
                                      </div>
                                    </form>
                                  </div>
                                </Modal>
                                <div className="col-md-12 mt8">
                                  <label className="control-label">
                                    Margin
                                  </label>
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      margin: 5,
                                      fontSize: 15,
                                    }}
                                  >
                                    <i
                                      className="fas fa-info-circle"
                                      data-for="tt"
                                      data-tip="The margin determines how much you will earn or lose on a single trade."
                                    />
                                    <ReactTooltip
                                      className={"overrideMe"}
                                      data-html={true}
                                      insecure={true}
                                      multiline={true}
                                      id="tt"
                                    />
                                  </span>
                                  <div className="">
                                    <div className="input-per">
                                      <input
                                        type="text"
                                        maxLength={8}
                                        inputMode="numeric"
                                        name="margin"
                                        value={this.state.margin}
                                        onChange={this.handleUserInput}
                                        className="form-control"
                                        placeholder="Margin"
                                      />
                                      <span> % </span>
                                    </div>

                                    {/* {this.state.marginError != "" ?
                                                    <label className="error">{this.state.marginError}</label>
                                                    : null} */}
                                    <ul className="detail-list mt5">
                                      <li>
                                        Current Bitcoin market price:{" "}
                                        {this.refinePrice(this.state.current)}{" "}
                                        {this.state.currency}
                                      </li>
                                      <li>
                                        Your Bitcoin{" "}
                                        {this.state.trade_type1
                                          ? "selling"
                                          : "buying"}{" "}
                                        price:{" "}
                                        {this.refinePrice(this.state.priceData)}
                                      </li>
                                      {this.state.profit >= 0 ? (
                                        <li>
                                          How much you earn per one Bitcoin:{" "}
                                          {this.refinePrice(this.state.profit)}
                                        </li>
                                      ) : (
                                        <li>
                                          How much you lose per one Bitcoin:{" "}
                                          {this.refinePrice(this.state.profit)}
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-12">
                                  <label className="control-label">
                                    Currency
                                  </label>
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      margin: 5,
                                      fontSize: 15,
                                    }}
                                  >
                                    <i
                                      className="fas fa-info-circle"
                                      data-for="tt"
                                      data-tip="Eg. 1. If you want to exchange Bitcoin with ETH, USDT etc select the crypto from the crypto tab. <br /> 2. If you want to trade any product of services like gift card, watches etc  select your preferred currency like USD, CAD etc from the fiat tab."
                                    />
                                    <ReactTooltip
                                      className={"overrideMe"}
                                      data-html={true}
                                      insecure={true}
                                      multiline={true}
                                      id="tt"
                                    />
                                  </span>
                                  <div className="">
                                    {/* <select name="currency" value={this.state.currency1} onChange={(e) => this.onClick_currency1(e)} className="form-control select-style1" onClick={() =>  this.setState({ currencyModal: true })}> */}
                                    <input
                                      type="text"
                                      className="form-control select-style1"
                                      placeholder="Select Currency"
                                      name="currency1"
                                      value={this.state.currency}
                                      onClick={() =>
                                        this.setState({ currencyModal: true })
                                      }
                                    />
                                    {/* {this.Populate_currency1()} */}
                                    <Modal
                                      isOpen={this.state.currencyModal}
                                      fade={false}
                                      style={{
                                        alignSelf: "center",
                                        justifyContent: "center",
                                        margintop: "10%",
                                        maxWidth: "950px",
                                        margintop: "60px",
                                      }}
                                    >
                                      <div className="cardBox max-WT-950 center-box">
                                        <div className="cardHead center-box pb10 text-center posRel">
                                          <span
                                            className="crossBtn"
                                            onClick={() =>
                                              this.setState({
                                                currencyModal: false,
                                              })
                                            }
                                          >
                                            <i className="fa fa-times" />
                                          </span>

                                          <h4 className="font18 mt0 mb5 text-center">
                                            Currency
                                          </h4>
                                        </div>
                                        <CurrencyModal
                                          currencyJson={currencyJson}
                                          setValue={this.setValue}
                                        />
                                      </div>
                                    </Modal>
                                    {/* </select> */}
                                    {this.state.currencyError != "" ? (
                                      <label className="error">
                                        {this.state.currencyError}
                                      </label>
                                    ) : null}
                                    <ul className="detail-list mt5">
                                      <li>
                                        {" "}
                                        Your offer to sell in the selected
                                        currency. For example, if you select US
                                        Dollars then your offer is visible for
                                        everyone willing to buy with US Dollar
                                        currency.
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <label className="control-label">
                                    Price/BTC
                                  </label>
                                  <div className="">
                                    <div className="load-img">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="0"
                                        name="price"
                                        value={this.state.price}
                                        disabled
                                      />
                                      {this.state.loading1 ? (
                                        <span>
                                          <img
                                            src={require("../assets/ajax-loader.gif")}
                                            style={{
                                              height: "25px",
                                              width: "25px",
                                            }}
                                            alt=""
                                          />
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-md-6">
                              <label className="control-label">
                                Min. transaction limit
                              </label>
                              <div className="">
                                <input
                                  type="text"
                                  inputMode="numeric"
                                  maxLength={9}
                                  name="minTransactionLimit"
                                  value={this.state.minTransactionLimit}
                                  onChange={this.handleUserInput}
                                  className="form-control"
                                />
                                {this.state.minTransactionLimitError != "" ? (
                                  <label className="error">
                                    {this.state.minTransactionLimitError}
                                  </label>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <label className="control-label">
                                Max. transaction limit
                              </label>
                              <div className="">
                                <input
                                  type="text"
                                  inputMode="numeric"
                                  maxLength={9}
                                  name="maxTransactionLimit"
                                  value={this.state.maxTransactionLimit}
                                  onChange={this.handleUserInput}
                                  className="form-control"
                                />
                                {this.state.maxTransactionLimitError != "" ? (
                                  <label className="error">
                                    {this.state.maxTransactionLimitError}
                                  </label>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <div className="col-md-6">
                              <label className="control-label">
                                Restrict amount to
                              </label>
                              <div className="">
                                {/* <input type="text" inputMode="numeric" name="restrictAmount" value={this.state.restrictAmount} onChange={this.handleUserInput} className="form-control" placeholder="" /> */}
                                <CreatableSelect
                                  value={this.state.selectedOption1}
                                  onChange={this.handleChange1}
                                  options={suggestions}
                                  isMulti
                                  isDisabled={
                                    this.state.minTransactionLimit !== "" &&
                                    this.state.maxTransactionLimit !== "" &&
                                    Number(this.state.minTransactionLimit) <=
                                      Number(this.state.maxTransactionLimit)
                                      ? false
                                      : true
                                  }
                                />
                                {/* {this.state.restrictAmountError != "" ?
                                                    <label className="error">{this.state.restrictAmountError}</label>
                                                    : null} */}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <label className="control-label">
                                Add Product Image (Optional)
                              </label>
                              <p style={{ color: "var(--text-grey)" }}>
                                *Upload photos of your product or service, these
                                are seen by traders before opening the trade
                              </p>
                              <div className="d-flex">
                                {this.state.imageArr.map((item, index) => {
                                  return (
                                    <div className="addImg_l">
                                      <img
                                        src={`${item.imageUrl}`}
                                        className=""
                                      />
                                      <span>
                                        <i
                                          class="fa fa-times"
                                          onClick={(e) =>
                                            this.removeImg(e, index)
                                          }
                                        />
                                      </span>
                                    </div>
                                  );
                                })}
                                {this.state.imageArr.length < 6 ? (
                                  <div className="addmoreImage">
                                    <input
                                      type="file"
                                      id="file_data4"
                                      name="file_data4"
                                      accept=".jpg, .jpeg, .png"
                                      className="form-control"
                                      value={this.state.file_data4}
                                      onChange={this.verify1}
                                    />
                                    <i className="fas fa-plus" />
                                  </div>
                                ) : null}
                              </div>
                              <p style={{ color: "var(--text-grey)" }}>
                                * Photo size should not be more than 2 MB.{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <label className="control-label">
                                Terms of trade
                              </label>
                              <div>
                                <textarea inputMode="text" maxLength={1500} className="form-control minHeight100" name="termTrade" value={this.state.termTrade} onChange={this.handleUserInput} placeholder=""></textarea>
                                {/* <CKEditor
                                  placeholder="Write here"
                                  termTrade={this.state.termTrade}
                                  onChange={this.onEditorChange}
                                  maxLength={1500}
                                  className="form-control cke_notification_warning"
                                  
                                /> */}
                                <li
                                  style={{
                                    fontSize: 12,
                                    color: "var(--text-grey)",
                                  }}
                                >
                                  * Max. limit is 1500 Characters.
                                </li>
                                {this.state.termTradeError !== "" ? (
                                  <label className="error">
                                    {this.state.termTradeError}
                                  </label>
                                ) : null}
                                <ul className="detail-list mt5">
                                  <li>
                                    Trade instructions must be clear and
                                    bulleted if possible.Clear instructions are
                                    required for smooth transactions.
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <div className="col-md-6">
                              <label className="control-label">Add tags</label>

                              <div className="">
                                {this.state.suggestions.length ? (
                                  <Select
                                    value={this.state.selectedOption}
                                    onChange={this.handleChange}
                                    options={this.state.suggestions}
                                    isMulti
                                  />
                                ) : null}
                                {this.state.addTagError != "" ? (
                                  <label className="error">
                                    {this.state.addTagError}
                                  </label>
                                ) : null}
                                <ul className="detail-list mt5">
                                  <li>
                                    Add quick tags that describe your offer
                                    terms such as "No verification required",
                                    "Physical Cards only".
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <label className="control-label">
                                Set payment window
                              </label>
                              <div className="">
                                <input
                                  inputMode="numeric"
                                  type="text"
                                  maxLength={3}
                                  name="setPaymentWindow"
                                  value={this.state.setPaymentWindow}
                                  onChange={this.handleUserInput}
                                  className="form-control"
                                  placeholder="30-360 minutes"
                                />
                                {this.state.setPaymentWindowError != "" ? (
                                  <label className="error">
                                    {this.state.setPaymentWindowError}
                                  </label>
                                ) : null}

                                <ul className="detail-list mt5">
                                  <li>
                                    This is the time you offer, to pay and
                                    complete the trade. Trade will auto-cancel
                                    if buyer has not clicked "I have paid"
                                    before payment window expires.
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <div className="col-md-6">
                              {isloggedIn !== null ? (
                                <div className="mt25">
                                  <button
                                    type="submit"
                                    className="btn btnGreen radius4"
                                    onClick={(e) => this.publish_Advertise(e)}
                                    disabled={!this.state.btnclick}
                                  >
                                    Save advertisement
                                  </button>
                                </div>
                              ) : (
                                <div className="mt25">
                                  {/* {this.state.loading ? <div className='loader'> <img className="" style={{ height: 100, width: 100 }} src={require('../assets/lg.gif')} /> </div> */}

                                  <button
                                    type="submit"
                                    className="btn btnGreen radius4"
                                    onClick={(e) =>
                                      this.props.history.push("/signup")
                                    }
                                  >
                                    Register here
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <label className="col-md-4 control-label">Payment Method</label>
                                                        <div className="col-md-8">
                                                            <select name="paymentMode" value={this.state.paymentMode} onChange={(e) => this.onClick_paymentMethod(e)} className="form-control select-style1">
                                                                <option>Select</option>
                                                                {this.Populate_paymentMethod()}
                                                            </select>
                                                            {this.state.paymentModeError != "" ?
                                                                <label className="error">{this.state.paymentModeError}</label>
                                                                : null}
                                                        </div>
                                                   
                                                    <div className="form-group row ">
                                                        <label className="col-md-4 control-label">Currency</label>
                                                        <div className="col-md-8">
                                                            <select name="currency" value={this.state.currency1} onChange={(e) => this.onClick_currency1(e)} className="form-control select-style1">

                                                                {this.Populate_currency1()}
                                                            </select>
                                                            {this.state.currencyError != "" ?
                                                                <label className="error">{this.state.currencyError}</label>
                                                                : null}
                                                            <ul className="detail-list mt5">
                                                                <li> Your offer to sell in the selected currency. For example, if you select US Dollars then your offer is visible for everyone willing to buy with US Dollar currency.</li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row ">
                                                        <label className="col-md-4 control-label">Margin</label>
                                                        <div className="col-md-8">
                                                            <div className="input-per">
                                                                <input type="text" maxLength={8} inputMode="numeric" name="margin" value={this.state.margin} 
                                                                onChange={this.handleUserInput} className="form-control" placeholder="0"></input>
                                                                <span>  % </span>
                                                            </div> */}

                {/* {this.state.marginError != "" ?
                                                    <label className="error">{this.state.marginError}</label>
                                                    : null} */}
                {/* <ul className="detail-list mt5">
                                                                    <li>Current Bitcoin market price :{this.refinePrice(this.state.current)}</li>
                                                                    <li>Your Bitcoin selling price: {this.refinePrice(this.state.priceData)}</li>
                                                                {this.state.profit >= 0 ?
                                                                    <li>How much you earn per one Bitcoin: {this.refinePrice(this.state.profit)}</li>
                                                                    :
                                                                    <li>How much you lose per one Bitcoin: {this.refinePrice(this.state.profit)}</li>
                                                                }
                                                            </ul>

                                                        </div>

                                                    </div> */}

                {/* <div className="form-group row align-items-center">
                                            <label className="col-md-4 control-label"> Amount </label>
                                            <div className="col-md-8">
                                                <input type="text" name="Amount" value={this.state.Amount}  className="form-control" placeholder="0%"></input>
                                               
                                            </div>
                                        </div> */}
                {/* <div className="form-group row align-items-center">
                                                <label className="col-md-4 control-label">Price equation</label>
                                                <div className="d-flex">
                                                <div className="w-100 align-items-center ml15">
                                                    <input type="text" className="form-control" placeholder="0" name="Amount" value={this.state.Amount} disabled />
                                                </div>
                                                <div className="minWidth100 pl10">
                                                    <select className="form-control select-style1" value={this.state.currency1} onChange={(e) => this.onClick_currency1(e)}>
                                                   
                                                    {this.Populate_currency1()}
                                                    </select>
                                                </div>
                                                </div>
                                            </div> */}
                {/* <div className="form-group row ">
                                                        <label className="col-md-4 control-label">Price/BTC</label>
                                                        <div className="col-md-8">
                                                            <div className="load-img">
                                                                <input type="text" className="form-control" placeholder="0" name="Amount" value={this.state.price} disabled />
                                                                {this.state.loading1 ?
                                                                    <span><img src={require("../assets/ajax-loader.gif")} style={{ height: "25px", width: "25px" }} alt="" /></span>
                                                                    : null}
                                                            </div>

                                                        </div>
                                                    </div>



                                                    <div className="form-group row ">
                                                        <label className="col-md-4 control-label">Min. transaction limit</label>
                                                        <div className="col-md-8">
                                                            <input type="text" inputMode="numeric" name="minTransactionLimit" value={this.state.minTransactionLimit} onChange={this.handleUserInput} className="form-control" />
                                                            {this.state.minTransactionLimitError != "" ?
                                                                <label className="error">{this.state.minTransactionLimitError}</label>
                                                                : null}
                                                        </div>
                                                    </div>
                                                    <div className="form-group row ">
                                                        <label className="col-md-4 control-label">Max. transaction limit</label>
                                                        <div className="col-md-8">
                                                            <input type="text" inputMode="numeric" name="maxTransactionLimit" value={this.state.maxTransactionLimit} onChange={this.handleUserInput} className="form-control" />
                                                            {this.state.maxTransactionLimitError != "" ?
                                                                <label className="error">{this.state.maxTransactionLimitError}</label>
                                                                : null}
                                                        </div>
                                                    </div>
                                                    <div className="form-group row ">
                                                        <label className="col-md-4 control-label">Restrict amount to</label>
                                                        <div className="col-md-8"> */}
                {/* <input type="text" inputMode="numeric" name="restrictAmount" value={this.state.restrictAmount} onChange={this.handleUserInput} className="form-control" placeholder="" /> */}
                {/* <CreatableSelect
                                                                value={this.state.selectedOption1}
                                                                onChange={this.handleChange1}
                                                                options={suggestions}
                                                                isMulti
                                                                isDisabled={((this.state.minTransactionLimit !== "") && (this.state.maxTransactionLimit !== "") && (Number(this.state.minTransactionLimit) <= Number(this.state.maxTransactionLimit))) ? false : true}
                                                            /> */}
                {/* {this.state.restrictAmountError != "" ?
                                                    <label className="error">{this.state.restrictAmountError}</label>
                                                    : null} */}
                {/* </div>



                                                    </div>
                                                    <div className="form-group row ">
                                                        <label className="col-md-4 control-label">Terms of trade</label>
                                                        <div className="col-md-8"> */}
                {/* <textarea inputMode="text" maxLength={1500} className="form-control minHeight100" name="termTrade" value={this.state.termTrade} onChange={this.handleUserInput} placeholder=""></textarea> */}
                {/* <CKEditor
                                                            
                                                                termTrade={this.state.termTrade}
                                                                onChange={this.onEditorChange}
                                                                maxLength={1500} className="form-control minHeight100"
                                                            />
                                                            <li style={{ fontSize: 12 }}>*  Max. limit is 1500 Characters.</li>
                                                            {this.state.termTradeError !== "" ?
                                                                <label className="error">{this.state.termTradeError}</label>
                                                                : null}
                                                            <ul className="detail-list mt5">
                                                                <li>Trade instructions must be clear and bulleted if possible.Clear instructions are required for smooth transactions.</li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row ">
                                                        <label className="col-md-4 control-label">Add tags</label>

                                                        <div className="col-md-8">
                                                            {this.state.suggestions.length ?
                                                                
                                                                : null} */}
                {/* <input inputMode="numeric" type="text" name="addTag" value={this.state.addTag} className="form-control" onChange={this.handleUserInput} placeholder="Enter tag" /> */}
                {/* {this.state.addTagError != "" ?
                                                                <label className="error">{this.state.addTagError}</label>
                                                                : null}
                                                            <ul className="detail-list mt5">
                                                                <li>Add quick tags that describe your offer terms such as "No verification required", "Physical Cards only".</li>
                                                            </ul>
                                                        </div>

                                                    </div> */}
                {/* <div className="form-group row align-items-center">
                                            <label className="col-md-4 control-label">BTC Amount</label>
                                            <div className="col-md-8">
                                                <input inputMode="numeric" type="text" name="BTC" value={this.state.BTC} className="form-control" onChange={this.handleUserInput} placeholder="Enter BTC" />
                                                {this.state.BTCError != "" ?
                                                    <label className="error">{this.state.BTCError}</label>
                                                    : null}
                                            </div>

                                        </div> */}

                {/* <div className="form-group row  mb0">
                                                        <label className="col-md-4 control-label">Set payment window</label>
                                                        <div className="col-md-8">
                                                            <input inputMode="numeric" type="text" maxLength={3} name="setPaymentWindow" value={this.state.setPaymentWindow} onChange={this.handleUserInput} className="form-control" placeholder="30-360 minutes" />
                                                            {this.state.setPaymentWindowError != "" ?
                                                                <label className="error">{this.state.setPaymentWindowError}</label>
                                                                : null}

                                                            <ul className="detail-list mt5">
                                                                <li>This is the time you offer, to pay and complete the trade. Trade will auto-cancel if buyer has not clicked "I have paid" before payment window expires.</li>
                                                            </ul>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                                {isloggedIn !== null ?
                                    <div className="text-center mt25">
                                  
                                        <button type="submit" className="btn btnGreen max-WT-300 pt10 pb10 radius4" onClick={(e) => this.publish_Advertise(e)} disabled={!this.state.btnclick}>Save advertisement</button>
                                    </div>
                                    :
                                    <div className="text-center mt25">
                                     {/* {this.state.loading ? <div className='loader'> <img className="" style={{ height: 100, width: 100 }} src={require('../assets/lg.gif')} /> </div> */}

                {/* <button type="submit" className="btn btnGreen max-WT-300 pt10 pb10 radius4" onClick={(e) => this.props.history.push("/signup")} >Register here</button> */}
                {/* </div>
                                } */}
              </form>
            </div>
          </section>
        </main>
        <Footer {...this.props} />
      </div>
    );
  }
}
