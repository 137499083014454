import openSocket from "socket.io-client";
import { getData } from "../services/globals";

// local server user to open for dev env (sanjay)
// const siteUrl = "http://172.16.6.15:5050/api/v1/";      //promo admin
// const siteUrl = "https://162.222.32.20:1450/api/v1/"     //staging
// const siteUrl = "http://172.16.2.3:5050/api/v1/"        //najmu sir
// const siteUrl = "http://localhost:5050/api/v1/"
//mylocal server sanjay
// const siteUrl = "http://172.16.6.177:5050/api/v1/"      // Brolix
// const siteUrl = "http://172.16.6.218:5050/api/v1/"     // Mongo bhai
// const siteUrl = "http://172.16.1.128:1450/api/v1/"     // Mongo bhai
const siteUrl = "https://www.coinbaazar.com/api/v1/"; // Official
// const siteUrl = "https://uat.coinbaazar.com/api/v1/";

// function encryptAlgo(id){
//   // alert(id)
//   let length = 25
//   let result = '';
//   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//   const charactersLength = characters.length;
//   let counter = 0;
//   while (counter < length) {
//     result += characters.charAt(Math.floor(Math.random() * charactersLength));
//     counter += 1;
//   }
//   let encryptId = id.split('').reverse().toString().replaceAll(',','')
//   let apiKey = encryptId+'/base64.l1/'+result
//   return apiKey
// }

// function encryptAlgo(id){
//   let alpha= ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','1','2','3','4','5','6','7','8','9','0']
//   let key=""
//   for(let i=0;i<id.length;i++){
//     for(let x=0;x< alpha.length;x++){
//        if(id[i]==alpha[x]){
//           key = key + (alpha[x+1]+alpha[i+1]) 
//         //console.log(key)

//        }
//     }
//  }
// return key
// }

function encryptAlgo(id){
  let alpha= ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','1','2','3','4','5','6','7','8','9','0']
  let key=""
  for(let i=0;i<id.length;i++){
    if(id[i]=='0'){
           key = key + id[i] + '0'
       } else {  
    for(let x=0;x< alpha.length;x++){
       if(id[i]==alpha[x]){
          key = key + (alpha[x+1]+alpha[i+1]) 
        console.log(key)

       }
    }
    }
 }
return key
}


export function Api(parameters, apiMethod, variables, token, id) {
  if(id && typeof id == 'string'){
    const apiKey = encryptAlgo(id)
    variables.apiKey = apiKey
  }
 console.log("body-after modify-----------------",variables)
  var init =
    apiMethod == "GET"
      ? {
          method: "GET",
          headers: {
            //  'Authorization': token
            "Content-Type": "application/json",
            token: token ? token : null,
            _id: id ? id : null,
            id: id ? id : null,
          },
        }
      : {
          method: apiMethod,
          headers: {
            _id: id ? id : null,
            id: id ? id : null,
            token: token ? token : null,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(variables),
        };

  return fetch(siteUrl + parameters, init)
    .then((res) =>
      res.json().then((data) => {
        var apiData = {
          status: res.status,
          data: data,
        };
        return apiData;
      })
    )
    .catch((err) => {
      return "error";
    });
}

export function getCurrency() {
  // return fetch("https://api.currencylayer.com/list?access_key=a14679e947a63f19dda62d21b223365b")
  return fetch(siteUrl+"user/get_currency_list")
    .then((res) =>
      res.json().then((data) => {
        return data.result;
      })
    )
    .catch((err) => {
      return err;
    });
}

export var openBox = false;
export function openBoxfun(val) {
  openBox = val;
  return openBox;
}
// export const socket = openSocket("https://uat.coinbaazar.com");
//  export const socket = openSocket('http://localhost:5050');

export const socket = openSocket("https://www.coinbaazar.com");
