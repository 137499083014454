import React, { Component, PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  showbox,
  showBoxfun,
  TRADE,
  TRADEfun,
  IMAGE,
  IMAGEfun,
  NAME,
  NAMEfun,
  RECEIVER,
  RECEIVERfun,
  getNotification,
  setNotification,
} from "../services/globals";
import { Api, socket, openBox, openBoxfun } from "../services/webservices";
import $ from "jquery";
import Notification from "react-web-notification";
import { base32 } from "../services/Decrypt";
import { toast_func } from "../components/toaster";
import "react-toastify/dist/ReactToastify.css";
import WebNotification from "react-web-notifications";
import loadjs from "loadjs";
import { ReactModal } from "../services/globals";
// import { Icon } from "@iconify/react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
let arrChat = [];
let arr1 = [];
let _arr = [];
let _name = "";
let _body = "";
let _this;
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      dropdownOpen: false,
      modalIsOpen: false,
      modalIsOpen11: false,
      headerBtnType: "/login",
      name: "",
      pageName: "",
      _id: "",
      chatbox: false,
      message: "",
      notificationWindow: true,
      a: 0,
      ignore: false,
      title: "",
      idd: 0,
      test: false,
      messageNot: "",
      selectCurrency: true,
      drop: false,
      menu: false,
      mode: null,
      balanceHide: false,  
      priceNow: 0,
      balanceUSD: 0,
    };
  }

  changeTheme = (val, e) => {
    console.log(val);
    localStorage.setItem("themeMode", Boolean(val));
    this.setState({ mode: val });
    this.props.parentCallback1(val.toString());
  };

  componentDidMount = async () => {
    let hideFlag = localStorage.getItem("balanceHide");
    this.setState({ balanceHide: hideFlag == "true" ? true : false  })
    this.changeTheme(
      localStorage.getItem("themeMode") == "true" ? true : false
    );
    showBoxfun(true);
    if (this.state.trade_id !== undefined) {
      TRADEfun(this.state.trade_id);
    }
    if (this.state.receiverId !== undefined) {
      var receiverData = {
        receiverId: this.state.receiverId,
        chatType: this.state._type1,
        trade_id: this.state.trade_id,
      };
      RECEIVERfun(receiverData);
    }
    socket.emit("removeUser", {
      userId: this.state._id,
    });

    var balance = localStorage.getItem("selectCurrency");
    if (balance == "mgt") {
      this.setState({ selectCurrency: false });
    } else {
      this.setState({ selectCurrency: true });
    }
    this.findUSD();
    loadjs(
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit",
      function() {
        console.log("Reloded");
      }
    );

    socket.on("notificationAlert", (resp) => {
      console.log("ALerttt=====#######====> ", resp);

      var title = resp.notiMessage.senderName;
      const options = {
        icon:
          "https://res.cloudinary.com/sumit9211/image/upload/v1549007653/rtokt4z1bwcofdvidx4p.png",
      };

      _name = resp.notiMessage.senderName || "Coinbaazar";
      _body = resp.notiMessage.message;
      this.setState({ messageNot: resp.notiMessage.message });
      this.setState({ showNotification: !this.state.showNotification }, () => {
        if (!this.state.showNotification) {
          this.setState({ showNotification: true });
        }
      });

      // this.spawnNotification("theBody",options.icon,title)
      //theBody,theIcon,theTitle
      // let data = {
      //   sender: resp.notiMessage.senderName,
      //   message: resp.notiMessage.message
      // }
      // registration.showNotification(title, options);

      // this.handleButtonClick(data);
    });
    // if(this.props.location.pathname == "/login")
    // {
    //   this.props.history.push(null,null,'/')
    //   window.onpopstate = function () {
    //     history.go(1);
    // };
    // }
    window.scrollTo(0, 0);

    var token = await localStorage.getItem("token");
    var id = await localStorage.getItem("data");
    if (id != null) {
      this.setState({ _id: id, token: token });
      socket.on("connect", () => console.log("#########################"));
      socket.emit("initChat", { userId: id });
    }

    if ((id !== null) & (token !== null)) {
      this.getBalance();
      this.updateLastActive();
    }

    if (id != null) {
      socket.emit("getUserBalance", {
        userId: id,
      });
      socket.on("getUserBalance", (resp) => {
        // alert('jgk')
        console.log("here we will get balance ===>>", resp);
        var num = Number(resp.balance);
        var n = num.toFixed(8);

        console.log("getting balance by socket====>", n);
        this.setState({ totalBalance: n });
      });

      if (IMAGE == "" || NAME == "") {
        // this.forceUpdate()
        this.getBalance();
      }
      //})

      socket.emit("notificationList", {
        userId: id,
      });
      socket.on("notificationList", (resp) => {
        arrChat = [];
        console.log("notificationList######################", resp.succ);
        if (resp.succ == undefined) {
          this.setState({ notificationList: [] }, () =>
            this.setState({ a: "0" })
          );
          return;
        }
        if (resp.succ.length == 0) {
          this.setState({ notificationList: [] }, () =>
            this.setState({ a: "0" })
          );
          return;
        }
        for (let i = 0; i < resp.succ.length; i++) {
          // if(resp.result["docs"][i]['notificationType'] == "chat")
          // {
          arrChat.push(resp.succ[i]);

          // }
        }
        // notification in global var

        this.setState({ notificationList: arrChat }, () => {
          let count = 0;
          let countList = this.state.a;

          this.state.notificationList.map(
            (item, index) =>
              !item["_id"]["isSeen"] ? (count = count + 1) : null
          );

          if (count < 100) {
            this.setState({ a: count });
            // , () => {

            //   if (countList < this.state.a) {

            //     let dataNotification = getNotification();

            //     if (dataNotification.length != 0) {
            //       if (dataNotification.length < arrChat.length) {

            //         let data = {
            //           sender: arrChat[0].senderName,
            //           message: arrChat[0].message
            //         }

            //         this.handleButtonClick(data);

            //       }
            //     }

            //   }

            // })
          } else {
            this.setState({ a: "99+" });
          }
        });

        setNotification(arrChat);
      });

      if (openBox == true) {
        this.setState({ chatbox: true }, () => this.checkChatBox());
      }
    }
    //var searchUrl1 = this.props.history.location.pathname
    // var id = searchUrl1.split("=")[1]
    console.log("header btn", this.props.history.location.pathname);
    this.setState({ headerBtnType: this.props.history.location.pathname });
    //this.setState({pageName:this.props.history.location.pathname})
    if (this.props.history.location.pathname == "/editn ") {
      this.setState({ pageName: "editheader" });
    }

    // if(showbox == false)
    //   {
    socket.on("receivemessage", (resp) => {
      this.setState({ message: "" });
      var index1 = _arr.map((item) => resp.requireData.message == item.message); //(obj.time)
      var index = index1.indexOf(true);
      var _index1 = _arr.map((item) => resp.requireData.time == item.time);
      var _index = _index1.indexOf(true);

      if (_index !== -1 && index !== -1) {
      } else {
        //

        var obj = {
          time: resp.requireData.time,
          message: resp.requireData.message,
        };
        _arr.push(obj);

        if (resp.requireData.type == "sender") {
          // showBoxfun(true)

          arr1.push(
            <li className="right clearfix">
              <div className="chat-inner-box clearfix">
                {/* <div className="chat-user-name">Roman1996</div> */}
                <p>{resp.requireData["message"]}</p>
                <span className="dateandtime">
                  {
                    new Date(resp.requireData["time"])
                      .toString()
                      .split("GMT")[0]
                  }
                </span>
              </div>
            </li>
          );
          //  this.setState({ populateAllChats: arr1 })
        } else if (resp.requireData.type == "receiver") {
          // showBoxfun(true)

          arr1.push(
            <li className="left clearfix">
              <div className="chat-inner-box clearfix">
                {/* <div className="chat-user-name">Roman1996</div> */}
                <p>{resp.requireData["message"]}</p>
                <span className="dateandtime">
                  {
                    new Date(resp.requireData["time"])
                      .toString()
                      .split("GMT")[0]
                  }
                </span>
              </div>
            </li>
          );
        }
        this.setState({ populateAllChats: arr1, message: "" });
      }
      // else{

      // }
      this.setState({
        populateAllChats: this.state.populateAllChats,
        message: "",
      });
    });
    // }

    if (TRADE !== "") {
      arr1 = [];
      // this.setState({receiverId:TRADE})
      this.chatHistoryAPI(TRADE);
    }

    if (IMAGE !== "") {
      this.setState({ profilePic: IMAGE });
    }

    if (NAME !== "") {
      this.setState({ name: NAME });
    }

    if (RECEIVER !== "") {
      this.setState({
        receiverId: RECEIVER.receiverId,
        _type1: RECEIVER.chatType,
        trade_id: RECEIVER.trade_id,
      });
    }

    //   if($(".chat-box").hasClass("chat-collapse")){
    //     $(".chat-box-head span").addClass("fa-minus").removeClass("fa-plus")
    //  }
    //       $(".chat-box-head span.fa").click(function(e){
    //       e.preventDefault();
    //       $(this).toggleClass("fa-plus fa-minus");
    //       $(this).parents("div.chat-box").toggleClass("chat-collapse");
    //       });
  };

  findUSD = async (btc) => {
    var parameter = {
      localCurrency: "USD",
      margin: "",
    };
    await Api(
      "user/priceEquationWithMargin",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      if (resp.status == 200) {
        if (resp.data.responseCode == 200) {
          var priceNow = resp.data.result.price;
          this.setState({priceNow: priceNow})
        } else if (resp.data.responseCode == 502) {
          return null;
        }
      }
    });
  };
  // notification
  handleNotificationOnClick = (e, tag) => {};

  handleNotificationOnError = (e, tag) => {};

  handleNotificationOnClose = (e, tag) => {};

  handleNotificationOnShow = (e, tag) => {
    this.playSound();
  };

  playSound(filename) {
    document.getElementById("sound").play();
  }

  //   componentDidUpdate(){
  //     let token = localStorage.getItem("token");
  //     let id = localStorage.getItem("data")
  //     if(id!==null  & token !== null ){
  //     // this.getBalance();
  //     }
  // }

  handleButtonClick = (data) => {
    // if (this.state.ignore) {
    //   return;
    // }

    // const now = Date.now();

    const title = data.sender;
    const body = data.message;
    // const tag = now;
    // const icon = 'http://georgeosddev.github.io/react-web-notification/example/Notifications_button_24.png';
    const icon =
      "https://res.cloudinary.com/sumit9211/image/upload/v1549007653/rtokt4z1bwcofdvidx4p.png";

    // Available options
    // See https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification
    const options = {
      // tag: tag,
      body: body,
      icon: icon,
      sound: "./sound.mp3", // no browsers supported https://developer.mozilla.org/en/docs/Web/API/notification/sound#Browser_compatibility
    };
    this.setState({
      title: title,
      options: options,
    });
  };

  //end Notification

  toggle(type) {
    if (type === "dropdown") {
      this.state.dropdownOpen
        ? this.setState({ dropdownOpen: false })
        : this.setState({ dropdownOpen: true });
    } else {
      this.state.showModal
        ? this.setState({ showModal: false })
        : this.setState({ showModal: true });
    }
  }

  logout = (e) => {
    e.preventDefault();
    var parameter = {
      _id: this.state._id,
    };
    Api(
      "user/logout",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      if (resp.status == 200) {
        if (resp.data.responseCode == 200) {
          socket.emit("removeUser", {
            userId: this.state._id,
          });
          setTimeout(() => {
            IMAGEfun("");
            NAMEfun("");
            this.setState({ profilePic: "", name: "", _id: "" });
            localStorage.removeItem("data");
            localStorage.removeItem("isLoggedin");
            localStorage.removeItem("data1");
            localStorage.removeItem("token");
            localStorage.removeItem("loginCredentials1");
            localStorage.removeItem("loginCredentials2");

            this.props.history.push("/");
          }, 100);
        } else {
        }
      }
    });
  };
  onClickTradeID = (i) => {
    this.props.history.push(`/send-buy-request/${base32.encode(i)}`);
  };

  initiateChat = (i) => {
    if (i == "Notificaton") {
      return;
    } else {
      this.onClickTradeID(i);
    }

    return;
    // this.state.notificationList[i]['_id']['isSeen'] = false;
    // this.setState({})
    if (this.props.location.pathname.split("/")[1] == "send-buy-request") {
      return;
    }

    var TRADE = this.state.notificationList[i]["_id"]["tradeId"];

    if (this.state.notificationList[i]["_id"]["receiverId"] == this.state._id) {
      this.setState({
        receiverId: this.state.notificationList[i]["_id"]["senderId"],
        trade_id: this.state.notificationList[i]["_id"]["tradeId"],
        message: "",
      });
    } else if (
      this.state.notificationList[i]["_id"]["senderId"] == this.state._id
    ) {
      this.setState({
        receiverId: this.state.notificationList[i]["_id"]["receiverId"],
        trade_id: this.state.notificationList[i]["_id"]["tradeId"],
        message: "",
      });
    }
    this.setState({
      chatbox: !this.state.chatbox,
      _type1: this.state.notificationList[i]["chatType"],
    });
    TRADEfun(TRADE);
    this.chatHistoryAPI(TRADE);
  };
  populateView = () => {
    let arr2 = [];

    if (this.state.notificationList.length == 0) {
      return <p>You don't have any notification</p>;
    }

    for (let i = 0; i < this.state.notificationList.length; i++) {
      arr2.push(
        <li
          key={i}
          onClick={() => {
            this.initiateChat(this.state.notificationList[i]["_id"]["tradeId"]);
            // this.forceUpdate();
          }}
        >
          {/* <span className="notifyImg"><img src="img/user-img.png" /></span> */}
          <div className="d-flex justify-content-between personal-notify">
            {this.state.notificationList[i]["_id"]["tradeId"] !==
            "Notificaton" ? (
              <h6 className="checknotify">
                <a
                  style={{ color: "#3e5c85" }}
                  href={`/send-buy-request/${base32.encode(
                    this.state.notificationList[i]["_id"]["tradeId"]
                  )}`}
                >
                  {this.state.notificationList[i]["senderName"]}
                </a>
              </h6>
            ) : (
              <h6 className="checknotify">
                <a href={`/Wallet`} style={{ color: "#3e5c85" }}>
                  {this.state.notificationList[i]["senderName"]}
                </a>
              </h6>
            )}
            {!this.state.notificationList[i]["_id"]["isSeen"] ? (
              <span className="notiVal">
                {this.state.notificationList[i]["count"]}
              </span>
            ) : null}
          </div>
          {this.state.notificationList[i]["message"] == " " ? (
            <p>
              <a
                href={`/send-buy-request/${base32.encode(
                  this.state.notificationList[i]["_id"]["tradeId"]
                )}`}
              >{`You have received an Image.`}</a>
            </p>
          ) : this.state.notificationList[i]["_id"]["tradeId"] !==
          "Notificaton" ? (
            <p>
              <a
                href={`/send-buy-request/${base32.encode(
                  this.state.notificationList[i]["_id"]["tradeId"]
                )}`}
              >
                {this.state.notificationList[i]["message"]}
              </a>
            </p>
          ) : (
            <p>
              <a href={`/Wallet`}>
                {this.state.notificationList[i]["message"]}
              </a>
            </p>
          )}
        </li>
      );
    }
    return arr2;
  };

  updateLastActive = () => {
    var id = localStorage.getItem("data");
    var token = localStorage.getItem("token");
    var userData = {
      userId: id,
    };
    Api("user/updateLastActive", "POST", userData, token, id).then((resp) => {
      console.log("--==>>hdr", resp);
      this.setState({ loading: false });
      if (resp.data.responseCode == 200) {
        console.log("HeaderApi==>");
      } else if (resp.data.responseCode == 602) {
        //alert("hhh===>"+this.state.modalIsOpen11)
        this.props.history.push("/");
        this.confirmBox();
      } else {
        //toast_func("error", resp.data.responseMessage)
      }
    });
  };

  getBalance = () => {
    var parameter = {
      _id: this.state._id,
    };
    // this.forceUpdate()
    console.log("getting balance into header=====>");

    Api(
      "user/userProfile",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      try {
        if (resp.status == 200) {
          console.log("==>", resp);
          if (
            resp.data.responseCode == 409 ||
            resp.data.responseCode == 410 ||
            resp.data.responseCode == 411
          ) {
            localStorage.removeItem("data");
            localStorage.removeItem("isLoggedin");
            localStorage.removeItem("data1");
            localStorage.removeItem("token");

            // toast_func("error", resp.data.responseMessage);
            setTimeout(() => {
              this.props.history.push("/");
            }, 1000);
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else if (resp.data.responseCode == 200) {
            // console.log('balance==>>',resp.data.result)
            var num = Number(resp.data.result.btc.total);
            var n = num.toFixed(8);
            var mgt = 0;
            if (this.state.totalMgt != undefined) {
              mgt = Number(resp.data.result.mgt.total);
            }
            var MGT = mgt.toFixed(8);
            console.log("getting balance by api=====>", n);
            // this.setState({ totalBalance: n, name: 'pk', profilePic: resp.data.result.profilePic }, () => this.saveDetails())

            this.setState(
              {
                totalBalance: n,
                totalMgt: MGT,
                balanceUSD: (this.state.priceNow * resp.data.result.btc.total).toFixed(2),
                kycStatus: resp.data.result.kyc_status,
                name: resp.data.result.user_name,
                refferalId: resp.data.result.referral_id,
                profilePic: resp.data.result.profilePic,
              },
              () => this.saveDetails()
            );
          }
          //else toast_func("error", resp.data.responseMessage)
        }
        //else toast_func("error", "some problem occured please contact administrator")
      } catch (ex) {
        //toast_func("error", "some problem occured please contact administrator")
      }
    });
  };
  saveDetails = () => {
    IMAGEfun(this.state.profilePic);
    NAMEfun(this.state.name);
  };
  // onClickMessage=(e)=>
  // {
  //   e.preventDefault();
  //   var id = e.target.id

  //  if(this.state.notificationList[0]['receiverId'] == this.state._id )
  //  {
  //   this.setState({receiverId:this.state.notificationList[0]['senderId'], trade_id:this.state.notificationList[0]['tradeId'],message:"" })
  //  }
  //  else if(this.state.notificationList[0]['senderId'] == this.state._id )
  //   {
  //     this.setState({receiverId:this.state.notificationList[0]['receiverId'], trade_id:this.state.notificationList[0]['tradeId'],message:"" })
  //   }
  //   this.setState({chatbox:!this.state.chatbox});

  // this.chatHistoryAPI(id)
  // }
  sendMessage1 = () => {
    if (this.state.message == "" || this.state.message == " ") {
      return;
    }

    if (this.state.message !== "" && !this.state.files1) {
      socket.emit("sendMessage", {
        // receiverId: this.state.receiverId.toString(),
        receiverId: [{ _id: `${this.state.receiverId}` }],
        senderId: this.state._id.toString(),
        message: this.state.message,
        tradeId: this.state.trade_id,
        image: null,
        notificationType: "chat",
        type: this.state._type1,
      });
      // this.chatHistoryAPI();
      this.setState({ textmessage: "", loader: true });
    }
  };
  checkChatBox = () => {
    this.setState({ box: !this.state.box }, () => {
      if (this.state.box) {
        socket.emit("currentlyChatting", {
          // receiverId: this.state.receiverId.toString(),
          receiverId: [{ _id: `${this.state.receiverId}` }],
          senderId: this.state._id.toString(),
          //  notificationType:"chat",
          //  type:'TRADE'
        });
      } else {
        socket.emit("currentlyChattingReverse", {
          // receiverId: this.state.receiverId.toString(),
          receiverId: [{ _id: `${this.state.receiverId}` }],
          senderId: this.state._id.toString(),
          //  notificationType:"chat",
          //  type:'TRADE'
        });
      }

      openBoxfun(this.state.box);

      socket.emit("notificationList", {
        userId: this.state._id,
      });
    });
    // if(this.state.box)
    // {
    //   // openBox=true
    //   openBoxfun(this.s)
    // }
    // else
    // {
    //   openBox=false
    // }
  };
  chatHistoryAPI = (TRADE) => {
    var parameter = {
      tradeId: TRADE,
      userId: this.state._id,
    };
    Api(
      "trade/chatHistory",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      try {
        if (resp.status == 200) {
          if (
            resp.data.responseCode == 409 ||
            resp.data.responseCode == 410 ||
            resp.data.responseCode == 411
          ) {
            localStorage.removeItem("data");
            localStorage.removeItem("isLoggedin");
            localStorage.removeItem("data1");
            localStorage.removeItem("token");

            toast_func("error", resp.data.responseMessage);
            setTimeout(() => {
              this.props.history.push("/");
            }, 1000);
          } else if (resp.data.responseCode == 502) {
            this.props.history.push("NotFound");
          } else if (resp.data.responseCode == 200) {
            arr1 = [];

            //toast_func("success", resp.data.responseMessage)
            for (var i = 0; i < resp.data.result.length; i++) {
              if (resp.data.result[i]["senderId"] !== this.state._id) {
                if (resp.data.result[i].image) {
                  //var time = new Date(resp.data.result[i]['time'])
                  arr1.push(
                    <li className="left clearfix">
                      <div className="chat-inner-box clearfix">
                        {/* <div className="chat-user-name">Roman1996</div> */}
                        <img id="blah" src={resp.data.result[i].image} />
                        {resp.data.result[i]["message"] == " " ? null : (
                          <p>{resp.data.result[i]["message"]}</p>
                        )}
                        <span className="dateandtime">
                          {
                            new Date(resp.data.result[i]["time"])
                              .toString()
                              .split("GMT")[0]
                          }
                        </span>
                      </div>
                    </li>
                  );
                  // arr1.push(<div className="chatCommonBlock otherside">
                  //     <img id="blah" src={resp.data.result[i].image} />
                  //     <p>{resp.data.result[i]["message"]}</p>
                  // </div>)
                } else {
                  arr1.push(
                    <li className="left clearfix">
                      <div className="chat-inner-box clearfix">
                        {/* <div className="chat-user-name">Roman1996</div> */}

                        <p>{resp.data.result[i]["message"]}</p>

                        <span className="dateandtime">
                          {
                            new Date(resp.data.result[i]["time"])
                              .toString()
                              .split("GMT")[0]
                          }
                        </span>
                      </div>
                    </li>
                  );
                  // arr1.push(
                  //     <div className="chatCommonBlock">
                  //         <p>{resp.data.result[i]["message"]}</p>
                  //     </div>
                  // )
                }
              } else {
                if (resp.data.result[i].image) {
                  arr1.push(
                    <li className="right clearfix">
                      <div className="chat-inner-box clearfix">
                        {/* <div className="chat-user-name">Roman1996</div> */}
                        <img id="blah" src={resp.data.result[i].image} />
                        {resp.data.result[i]["message"] == " " ? null : (
                          <p>{resp.data.result[i]["message"]}</p>
                        )}
                        <span className="dateandtime">
                          {
                            new Date(resp.data.result[i]["time"])
                              .toString()
                              .split("GMT")[0]
                          }
                        </span>
                      </div>
                    </li>
                  );
                } else {
                  arr1.push(
                    <li className="right clearfix">
                      <div className="chat-inner-box clearfix">
                        {/* <div className="chat-user-name">Roman1996</div> */}

                        <p>{resp.data.result[i]["message"]}</p>
                        <span className="dateandtime">
                          {
                            new Date(resp.data.result[i]["time"])
                              .toString()
                              .split("GMT")[0]
                          }
                        </span>
                      </div>
                    </li>
                  );
                }
              }
            }
            this.setState({ populateAllChats: arr1 });
          } else toast_func("error", resp.data.responseMessage);
        }
      } catch (ex) {
        //toast_func("error", "Some problem occured please contact administrator")
      }
    });
  };

  handleInputMessage = (e) => {
    let type = e.target.name;
    let value = e.target.value;
    this.setState({ [type]: value });
  };
  confirmBox() {
    //e.preventDefault()
    this.setState({ modalIsOpen11: true });
    var parameter = {
      _id: this.state._id,
    };
    Api(
      "user/logout",
      "POST",
      parameter,
      this.state.token,
      this.state._id
    ).then((resp) => {
      if (resp.status == 200) {
        if (resp.data.responseCode == 200) {
          socket.emit("removeUser", {
            userId: this.state._id,
          });
          setTimeout(() => {
            this.setState({ modalIsOpen11: true });
            // IMAGEfun("")
            // NAMEfun("")
            this.setState({ profilePic: "", name: "", _id: "" });
            localStorage.removeItem("data");
            localStorage.removeItem("isLoggedin");
            localStorage.removeItem("data1");
            localStorage.removeItem("token");

            //this.props.history.push("/")
          }, 1000);
        } else {
        }
      }
    });
  }
  parent = (val) => {
    localStorage.setItem("tab1", val);
    this.props.parentCallback(val);
  };
  toWallet = (val) => {
    localStorage.setItem("tab", val);
    this.props.walletCallback(val);
  };

  render() {
    var isloggedIn = localStorage.getItem("isLoggedin");
    return isloggedIn == "true" ? (
      <div>
        <header className={`header ${this.state.pageName} `}>
          <div className="container1440">
            {/* <div  style = {{textAlign:'right'}} id="google_translate_element"></div> */}
            <nav className="d-flex w-100 align-items-center justify-content-between">
              <div className="logo">
                <Link
                  to={"/"}
                  onClick={() => this.changeBtnTypeState("/maindashboard")}
                >
                  {/* <a href="/"> */}
                  {this.state.showNotification ? (
                    <WebNotification
                      title={`${_name}`}
                      icon="https://res.cloudinary.com/do15uxr7o/image/upload/v1555069328/logo/lOGO.jpg"
                      body={
                        this.state.messageNot // icon='https://res.cloudinary.com/sumit9211/image/upload/v1549007653/rtokt4z1bwcofdvidx4p.png' // the title prop is required
                      }
                      timeout={2500}
                    />
                  ) : null
                  //onClickFn={ () => window.open('http://www.google.com/', '_blank') } // open your own site on notification click
                  }
                  <span>
                    <img
                      src={require("../assets/newImages/header_logo.png")}
                      alt="Logo"
                    />
                  </span>

                  {/* </a>  */}
                </Link>
              </div>

              <div
                className={this.state.menu ? "menu menu-slide" : "menu"}
                id="menubox"
              >
                <span
                  className="close-menu"
                  id="menuClose"
                  onClick={() => this.setState({ menu: false })}
                />
                <ul className="dropDownStyle">
                  <li className="dropdown newBtn">
                    {/* <a to="/buycoindetail">BUY BITCOINS</a> */}
                    {/* <a className="btn btnHeader" href = "https://blog.coinbaazar.com" target="_blank" > blog</a> */}

                    {/* <Link to={"/cointab/buy"} className={`btn btnHeader ${this.state.headerBtnType == '/cointab/buy' ? 'btnActive' : ''}`}>BUY/SELL BITCOINS</Link> */}
                    <a
                      href="/buy-bitcoins"
                      className={`btn ${
                        this.state.headerBtnType == "/cointab1/buy"
                          ? "btnActive"
                          : ""
                      }`}
                    >
                      Buy/Sell Bitcoins
                    </a>
                  </li>
                  <li className="newBtn">
                    <div
                      className={
                        this.state.drop == false
                          ? " common_ack"
                          : "dropdown common_ack"
                      }
                      style={
                        this.state.drop == true ? { display: "block" } : null
                      }
                    >
                      {/* <a className="btn-bell" to="javascript:void(0);"  > */}
                      <span>
                        <div
                          className="dropdown-menu"
                          style={{
                            width: "250px",
                            marginTop: -10,
                            backgroundColor: "var(--white-color)",
                          }}
                        >
                          {/* <h3 className="dropdown-title">Notifications</h3> */}
                          <div className="dropdown-body">
                            <ul
                              className="dropdown_list"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                              }}
                            >
                              <Link
                                to={"/Wallet"}
                                className="dropbtn newbtn1"
                                onClick={() => this.toWallet("receive-btc")}
                              >
                                <span className=""> Wallet</span>
                              </Link>

                              <Link
                                to={"/create-gift-card"}
                                className="dropbtn newbtn1"
                              >
                                <span className="">CoinBaazar gift card</span>
                              </Link>

                              <Link
                                to={"/Wallet"}
                                className="dropbtn newbtn1"
                                onClick={() => this.toWallet("salary")}
                              >
                                <span className="">Salary</span>
                              </Link>

                              <Link
                                to={"/Wallet"}
                                className="dropbtn newbtn1"
                                onClick={() => this.toWallet("invoice")}
                              >
                                <span className="">Merchant Invoicing</span>
                              </Link>

                              <Link
                                to={"/dashboard"}
                                className="dropbtn newbtn1"
                                onClick={() => this.parent("2")}
                              >
                                <span className="">My Offers/Ads</span>
                              </Link>

                              <Link
                                to={"/dashboard"}
                                className="dropbtn newbtn1"
                                style={{ marginBottom: 10 }}
                                onClick={() => this.parent("3")}
                              >
                                <span className="">All Trades</span>
                              </Link>
                            </ul>
                          </div>
                        </div>

                        <a
                          onClick={() =>
                            this.setState({
                              drop: true,
                            })
                          }
                          className={`btn 
                           ${
                             this.state.headerBtnType == "/maindashboard"
                               ? "btnActive"
                               : ""
                           }`}
                          style={{ color: "var(--text-grey)" }}
                        >
                          Dashboard
                        </a>
                      </span>
                      {/* </a> */}
                    </div>

                    {/* <a href="/maindashboard" className={`btn btnHeader ${this.state.headerBtnType == '/maindashboard' ? 'btnActive' : ''}`}>DASHBOARD</a> */}
                  </li>
                  <li className="dropdown newBtn">
                    <Link
                      to={"/create-offer"}
                      className={`btn ${
                        this.state.headerBtnType == "/createadvertise"
                          ? "btnActive"
                          : "" // onClick = { ()=> this.changeBtnTypeState('/createadvertise') }
                      }`}
                    >
                      Create Ad.
                    </Link>
                    {/* <a to="/creatadvertise">CREATE ADVERTISE</a> */}
                  </li>
                  <li className="dropdown newBtn">
                    <a
                      className={`btn ${
                        this.state.headerBtnType == "/faq" ? "btnActive" : ""
                      }`}
                      href="https://blog.coinbaazar.com"
                      target="_blank"
                    >
                      Blog
                    </a>
                    {/* <Link to={'/faq'} 
                      // onClick = { ()=> this.changeBtnTypeState('/maindashboard') }
                      className={`btn btnHeader ${this.state.headerBtnType == '/faq' ? 'btnActive' : ''}`}>HELP</Link> */}
                    {/* <a to="/faq">HELP</a> */}
                  </li>
                  <li className="dropdown " style={{ marginLeft: 5 }}>
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="checkbox"
                      style={{ display: "none" }}
                    />
                    <label
                      // for="checkbox"
                      className="checkbox-label"
                      onClick={(e) => this.changeTheme(!this.state.mode, e)}
                    >
                      <i className="fas fa-moon" />
                      <i className="fas fa-sun" />
                      <span
                        className="ball"
                        style={
                          this.state.mode
                            ? { transform: "translateX(24px)" }
                            : { transform: "translateX(0px)" }
                        }
                      />
                    </label>
                  </li>

                  {/* <li className="dropdown"><div style = {{textAlign:'right'}} id="google_translate_element"></div></li> */}
                  {/* {this.state.notification?
                  this.state.notificationList ?
                  <label id={`${this.state.notificationList[0]['tradeId']}`} onClick={(e)=>{this.onClickMessage(e)}} >{ this.state.notificationList.length !== 0 ?this.state.notificationList[0].message :null}</label>
                  :null
                  :null} */}
                </ul>
              </div>

              {/* <script type="text/javascript" src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script> */}

              <audio id="sound" preload="auto">
                <source src="./sound.mp3" type="audio/mpeg" />
                <source src="./sound.ogg" type="audio/ogg" />
                <embed
                  hidden={true}
                  autostart={"false"}
                  loop={false}
                  src="./sound.mp3"
                />
              </audio>
              {/* <audio id='sound' preload='auto'>
                  <source src='./sound.mp3' type='audio/mpeg' />
                  <source src='./sound.ogg' type='audio/ogg' />
                  <embed hidden='true' autostart='false' loop='false' src='./sound.mp3' />
                </audio> */}
              <div className="login-box d-flex align-items-center justify-content-end">
                <div className="dropdown common_ack">
                  {/* <a className="btn-bell" to="javascript:void(0);"  > */}
                  <span className="btn-bell">
                    <div className="dropdown-menu">
                      <h3 className="dropdown-title">Notifications</h3>
                      <div className="dropdown-body">
                        <ul className="dropdown_list">
                          {this.state.notificationList ? (
                            this.populateView()
                          ) : (
                            <p>You don't have any notification</p>
                          )}
                          {/* <li>
                          <span className="notifyImg"><img src="img/user-img.png" /></span>
                                      <h6>Lorem Ipsum is simply dummy </h6>
                                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                  </li>
                                  <li>
                          <span className="notifyImg"><img src="img/user-img.png" /></span>
                                      <h6>Lorem Ipsum is simply dummy </h6>
                                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                  </li> */}
                        </ul>
                      </div>
                    </div>

                    <i className="fas fa-bell" />
                    {this.state.notificationList ? (
                      this.state.a !== 0 ? (
                        <span className="notiVal">{this.state.a}</span>
                      ) : null
                    ) : null}
                  </span>
                  {/* </a> */}
                </div>
                <Dropdown
                  isOpen={this.state.dropdownOpen}
                  toggle={() => this.toggle("dropdown")}
                >
                  <DropdownToggle>
                    {this.state.profilePic ? (
                      <a>
                        <span>
                          <img src={`${this.state.profilePic}`} alt="Logo" />
                        </span>
                      </a>
                    ) : null}
                  </DropdownToggle>
                  <DropdownMenu
                    className="head-drop-down"
                    style={{ width: 270, 'word-break': 'break-all' }}
                  >
                    {/* <DropdownItem > */}
                    <Link to={"/profile"} className="dropdown-item">
                      <div className="d-flex align-items-center">
                        <div className="user-pic">
                          <img src={`${this.state.profilePic}`} alt="Logo" />
                        </div>

                        <div className="userDetails">
                          {this.state.name ? (
                            <div>
                              <label
                                className="d-block"
                                style={{ textWrap: "wrap" }}
                              >
                                {this.state.name}
                              </label>
                              {this.state.kycStatus == "APPROVED" && (
                                <img
                                  src={require("../assets/images/completed.png")}
                                  width={"30px"}
                                  height={"30px"}
                                />
                              )}
                            </div>
                          ) : null}
                          {/* {this.state.refferalId ?
                              <span className="">Ref_ID {this.state.refferalId}</span>
                              : null} */}
                          {/* {this.state.name ?
                            <span className="d-block">{this.state.name}</span>
                            :
                            null} */}
                        </div>
                        <div>
                          {/* {this.state.kycStatus=="APPROVED" && ( */}
                          {/* <img  src={require("../assets/images/completed.png")} width={"30px"} height={'30px'}/> */}
                          {/* )}  */}
                        </div>
                      </div>
                    </Link>
                    {/* </DropdownItem> */}
                    <DropdownItem divider className="dropdown-divider" />
                    {/* <DropdownItem > */}
                    <Link to={"/editprofile"} className="dropdown-item">
                      {" "}
                      <span>
                        <img
                          src={require("../assets/images/profile.png")}
                          alt="Logo"
                        />
                      </span>{" "}
                      Edit Profile
                    </Link>
                    {/* </DropdownItem> */}
                    <DropdownItem divider />
                    {/* <DropdownItem> */}
                    {/* <Link to={"/Wallet"} className="dropdown-item">
                      <span>
                        <img
                          src={require("../assets/images/wallet.png")}
                          alt="Logo"
                        />
                      </span>{" "}
                      Wallet
                    </Link> */}
                    {/* </DropdownItem> */}
                    {/* <DropdownItem divider /> */}
                    {/* <DropdownItem> */}
                    {/* <Link to={"/create-gift-card"} className="dropdown-item">
                      <span>
                        <img
                          src={require("../assets/newImages/giftCardB.png")}
                          alt="Logo"
                        />
                      </span>{" "}
                      Create gift card
                    </Link> */}
                    {/* <DropdownItem> */}
                    <DropdownItem divider />
                    <Link to={"/referFriend"} className="dropdown-item">
                      <span>
                        <img
                          src={require("../assets/images/refer.png")}
                          alt="Logo"
                        />
                      </span>{" "}
                      Refer a friend
                    </Link>
                    {/* </DropdownItem> */}
                    <DropdownItem divider />
                    {/* <DropdownItem> */}
                    <Link to={"/twofactor"} className="dropdown-item">
                      <span>
                        <img
                          src={require("../assets/images/security.png")}
                          alt="Logo"
                        />
                      </span>{" "}
                      Account Security
                    </Link>
                    {/* </DropdownItem> */}
                    <DropdownItem divider />
                    {/* <DropdownItem > */}
                    <Link
                      className="dropdown-item"
                      to={"#logoutModal"}
                      data-toggle="modal"
                      data-target="#logoutModal"
                      onClick={() => this.toggle("modal")}
                    >
                      <span>
                        <img
                          src={require("../assets/images/logout1.png")}
                          alt="Logo"
                        />
                      </span>{" "}
                      Sign Out
                    </Link>
                    {/* </DropdownItem> */}
                  </DropdownMenu>
                </Dropdown>
                {/* <Link to={"/maindashboard"}> <a to="#" className="dashboardIcon"><i className="fa fa-tachometer-alt"></i></a></Link> */}
                {this.state.name ? (
                  <div className="profile-name ">
                    {" "}
                    <span>{this.state.name}</span>
                    <br />
                    {this.state.selectCurrency == true ? (
                      // <span>
                      //   {this.state.totalBalance} <span>₿<i class="fa fa-eye" aria-hidden="true"></i></span>
                      // </span>
                      <span>
                      {this.state.balanceHide===false?this.state.totalBalance:<b>****</b>} <span className="btc_i">₿</span> 
                      <div>{this.state.balanceHide===false?this.state.balanceUSD+" USD":""} {this.state.balanceHide===false?(<i class="fa fa-eye" onClick={()=>{this.setState({balanceHide:true});localStorage.setItem("balanceHide",true)}} aria-hidden="true"></i>):(<i class="fa fa-eye-slash" onClick={()=>{this.setState({balanceHide:false});localStorage.setItem("balanceHide",false)}} aria-hidden="true"></i>)}</div>
                    </span>
                    ) : (
                      <span>
                        {this.state.totalMgt} <span>MGTC</span>
                      </span>
                    )}
                  </div>
                ) : (
                  <div>
                    <img
                      src={require("../assets/ajax-loader.gif")}
                      style={{ height: "25px", width: "25px" }}
                      alt=""
                    />
                  </div>
                )}
                {/* <a href="/maindashboard" className="dashboardIcon"><i className="fa fa-tachometer-alt"></i></a> */}
              </div>
              <div>
                <a
                  to="javascript:;"
                  className="mob-menu"
                  id="menuShow"
                  onClick={() => this.setState({ menu: true })}
                >
                  <span className="menu-bar" />
                </a>
              </div>
            </nav>
          </div>

          <Modal
            isOpen={this.state.showModal}
            toggle={() => this.toggle("modal")}
          >
            <ModalBody>
              <img
                src={require("../assets/images/icon-logout.png")}
                className="logOut-img"
                alt=""
              />
              <h5 className="mb30 mt30 font17">
                Are you sure you want to Logout?
              </h5>
              <button
                onClick={(e) => this.logout(e)}
                className="btn btnGreen max-WT-120 font16"
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btnRed max-WT-120 ml10 font16"
                data-dismiss="modal"
                onClick={() => this.setState({ showModal: false })}
              >
                No
              </button>
            </ModalBody>
          </Modal>
        </header>
        {this.state.chatbox ? (
          <div className={`chat-box ${this.state.box ? "chat-collapse" : ""}`}>
            <div className="chat-box-head">
              Welcome to Livechat{" "}
              <span
                onClick={() => this.checkChatBox()}
                className={`${this.state.box ? "fa fa-minus" : "fa fa-plus"}`}
              />
            </div>
            <div className="chat-box-body">
              <ul className="chat">
                {this.state.populateAllChats
                  ? this.state.populateAllChats
                  : null}
                {/* <li className="right clearfix">
               <div className="chat-inner-box clearfix">
                  <div className="chat-user-name">Username</div>
                  <p>Hi there, <br/>
                     Great to have you there! how many I help you?
                  </p>
                  <span className="dateandtime">09.11.2017, 3:20</span>
               </div>
            </li>
            <li className="left clearfix">
               <div className="chat-inner-box clearfix">
                  <div className="chat-user-name">Roman1996</div>
                  <p>
                     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur bibendum ornare
                     dolor, quis ullamcorper ligula sodales.
                  </p>
                  <span className="dateandtime">09.11.2017, 3:20</span>
               </div>
            </li>
            <li className="left clearfix">
               <div className="chat-inner-box clearfix">
                  <div className="chat-user-name">Roman1996</div>
                  <p>
                     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur bibendum ornare
                     dolor, quis ullamcorper ligula sodales.
                  </p>
                  <span className="dateandtime">09.11.2017, 3:20</span>
               </div>
            </li> */}
              </ul>
              <div className="chat-comment-box">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Message..."
                  onKeyDown={(e) => {
                    if (e.which == 13) {
                      this.sendMessage1(e);
                    }
                  }}
                  value={this.state.message}
                  name="message"
                  onChange={this.handleInputMessage}
                />
                <button
                  className="btn send-chat-btn"
                  type="submit"
                  onClick={() => this.sendMessage1()}
                >
                  <i className="fa fa-paper-plane" />
                </button>
              </div>
            </div>
          </div>
        ) : null}

        {/* <script type="application/javascript">
   
   if($(".chat-box").hasClass("chat-collapse")){
        $(".chat-box-head span").addClass("fa-minus").removeClass("fa-plus")
     }
     $(".chat-box-head span.fa").click(function(e){
     e.preventDefault();
     $(this).toggleClass("fa-plus fa-minus");
     $(this).parents("div.chat-box").toggleClass("chat-collapse");
     });
   
   </script> */}
      </div>
    ) : (
      <header className="header">
        <Modal isOpen={this.state.modalIsOpen11}>
          <ModalBody>
            <img
              src={require("../assets/images/oops.png")}
              className="logOut-img"
              style={{ width: "120px" }}
              alt=""
            />
            <h5 className="mb30 mt30 font17">
              You have been logged out,as you have just sign in from another
              system.If it was not you please sign in again and change your
              password or contact coinbaazar support.
            </h5>
            <button
              onClick={() =>
                this.setState({
                  modalIsOpen11: false,
                })
              }
              className="btn btnGreen max-WT-120 font16"
            >
              OK
            </button>
          </ModalBody>
        </Modal>
        <div className="container1440">
          <nav className="d-flex w-100 align-items-center justify-content-between">
            <div className="logo">
              <Link to={"/"}>
                <span>
                  <img
                    src={require("../assets/newImages/header_logo.png")}
                    alt="Logo"
                  />
                </span>
              </Link>
            </div>

            <a
              to="javascript:;"
              className="mob-menu"
              id="menuShow"
              onClick={() => this.setState({ menu: true })}
            >
              <span className="menu-bar" />
            </a>
            <div className="loggin-box">
              <div
                className={this.state.menu ? "menu menu-slide" : "menu"}
                id="menubox"
              >
                <span
                  className="close-menu"
                  id="menuClose"
                  onClick={() => this.setState({ menu: false })}
                />
                <ul className="dropDownStyle">
                  {/* onHoverDropdown */}
                  <li className="dropdown newBtn">
                    {/* <a to="/buycoindetail">BUY BITCOINS</a> */}
                    {/* <a className="btn btnHeader/createadvertise" href = "https://blog.coinbaazar.com" target="_blank" > blog</a> */}
                    <a
                      href="/buy-bitcoins"
                      className={`btn  ${
                        this.state.headerBtnType == "/cointab1/buy"
                          ? "btnActive"
                          : ""
                      }`}
                    >
                      Buy/Sell Bitcoins
                    </a>
                  </li>
                  {/* <li className="dropdown">
                
                  <a href="/cointab1/sell" className={`btn btnHeader ${this.state.headerBtnType == '/cointab1/sell' ? 'btnActive' : ''}`} >SELL BITCOINS</a>
                  </li> */}
                  <li className="dropdown newBtn">
                    <Link
                      to={"/create-offer"}
                      className={`btn ${
                        this.state.headerBtnType == "/createadvertise"
                          ? "btnActive"
                          : ""
                      }`}
                    >
                      Create Ad.
                    </Link>
                    {/* <a href="/creatadvertise" className={`btn btnHeader ${this.state.headerBtnType == '/createadvertise' ? 'btnActive' : ''}`}>CREATE ADVERTISE</a> */}
                  </li>

                  <li className="dropdown newBtn">
                    <a
                      className={`btn  ${
                        this.state.headerBtnType == "/faq" ? "btnActive" : ""
                      }`}
                      href="https://blog.coinbaazar.com"
                      target="_blank"
                    >
                      Blog
                    </a>
                    {/* <a to="/faq">HELP</a> */}
                  </li>
                  <li className="dropdown">
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="checkbox"
                      style={{ display: "none" }}
                    />
                    <label
                      for="checkbox"
                      className="checkbox-label"
                      onClick={(e) => this.changeTheme(!this.state.mode, e)}
                    >
                      <i className="fas fa-moon" />
                      <i className="fas fa-sun" />
                      <span
                        className="ball"
                        style={
                          this.state.mode
                            ? { transform: "translateX(24px)" }
                            : { transform: "translateX(0px)" }
                        }
                      />
                    </label>
                  </li>
                </ul>
              </div>
              <Link
                to={"/login"}
                className={`btn newBtn btnPad ${
                  this.state.headerBtnType == "/login" ? "btnActive" : "" // onClick = { ()=> this.changeBtnTypeState(this) }
                }`}
              >
                Login
                {/* <img src={require("../assets/images/login.png")} /> */}
              </Link>
              <Link
                to="/signup"
                className={`btn newBtn btnPad ${
                  this.state.headerBtnType == "/signup" ? "btnActive" : "" // onClick = { ()=> this.changeBtnTypeState(this) }
                }`}
              >
                Sign Up
                {/* <img src={require("../assets/images/logout.png")} /> */}
              </Link>
              {/* <div className="gogleLang" id="google_translate_element"></div> */}
            </div>
            {/* <div id="google_translate_element"></div> */}
          </nav>
          <div
            className={`modal fade ${this.state.showModal ? "show" : ""}`}
            id="logoutModal"
            tabIndex="-1"
            role="dialog"
          >
            <div className="modal-dialog max-WT-450" role="document">
              <div className="modal-content">
                <div className="modal-body minHeight300 d-flex align-items-center text-center">
                  <div className="w-100">
                    <img
                      src="images/icon-logout.png"
                      className="logOut-img"
                      alt=""
                    />
                    <h5 className="mb30 mt30 font17">
                      Are you sure you want to Logout?
                    </h5>
                    <a
                      to="index.html"
                      className="btn btnGreen max-WT-120 font16"
                    >
                      Yes
                    </a>
                    <button
                      type="button"
                      className="btn btnRed max-WT-120 ml10 font16"
                      data-dismiss="modal"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );

    //       isloggedIn === "true" ?
    //         <header classNameName="header">
    //           <div classNameName="container1440">
    //             <nav classNameName="d-flex w-100 align-items-center justify-content-between">
    //               <div classNameName="logo">
    //                 <a to="#">
    //                   <Link to='/'>
    //                     <span>
    //                       <img src={require("../assets/images/logo.png")} alt="Logo" />
    //                     </span>
    //                   </Link>
    //                 </a>
    //               </div>
    //               <div classNameName="menu " id="menubox">
    //                 <span classNameName="close-menu" id="menuClose"></span>
    //                 <ul classNameName="onHoverDropdown dropDownStyle">
    //                   <Link to='/buycoindetail'>
    //                     <li classNameName="dropdown"><a>BUY BITCOINS</a></li>
    //                   </Link>
    //                   <Link to='/sellbitcoindetail'>
    //                     <li classNameName="dropdown"><a>SELL BITCOINS</a></li>
    //                   </Link>
    //                   <Link to='/creatadvertise'>
    //                     <li classNameName="dropdown"><a>CREATE ADVERTISE</a></li>
    //                   </Link>
    //                   <Link to="/faq">
    //                     <li classNameName="dropdown"><a>HELP</a></li>
    //                   </Link>
    //                   <Link to="/wallet">
    //                     <li classNameName="dropdown"><a>Wallet</a></li>
    //                   </Link>
    //                 </ul>
    //               </div>
    //               <div classNameName="login-box d-flex align-items-center justify-content-end">
    //                 <a classNameName="btn-bell" to="javascript:void(0);">
    //                   <i classNameName="fas fa-bell"></i>
    //                   <span classNameName="notiVal">2</span>
    //                 </a>
    //                 <div classNameName={`top_user_info dropdown ${this.state.dropdownToggle ? "show" : ""}`}>
    //                   <a to="#" classNameName="head-user-img dropdown-toggle" data-toggle="dropdown" onClick={() => this.toggle()} aria-expanded={this.state.dropdownToggle ? "true" : "false"}>
    //                     <img src={require("../assets/images/user-icon.png")} alt="Logo" />
    //                   </a>
    //                   <div classNameName={`dropdown-menu head-drop-down ${this.state.dropdownToggle ? "show" : ""}`}>
    //                     <a classNameName="dropdown-item" ><Link to="/profile">John Travolta</Link></a>
    //                     <a classNameName="dropdown-item" ><Link to="/editprofile">Edit Profile</Link></a>
    //                     <a classNameName="dropdown-item" ><Link to="/wallet">Wallet</Link></a>
    //                     <a classNameName="dropdown-item" ><Link to="/transactionhistory">Account Security</Link></a>
    //                     <a classNameName="dropdown-item" data-toggle="modal" data-target="#logoutModal">
    //                       <Link to="/logout">Logout</Link>
    //                     </a>
    //                   </div>
    //                 </div>
    //                 <a to="dashboard.html" classNameName="dashboardIcon"><i classNameName="fas fa-tachometer-alt"></i></a>
    //               </div>
    //               <a to="javascript:;" classNameName="mob-menu" id="menuShow">
    //                 <span classNameName="menu-bar"></span>
    //               </a>
    //             </nav>
    //           </div>
    //           {/* <ReactModal visible={this.state.modalIsOpen}>
    //             <div className={`modal fade ${this.state.modalIsOpen ? "show" : ""}`} id="logoutModal" tabindex="-1" role="dialog" >
    //               <div className="modal-dialog max-WT-450" role="document">
    //                 <div className="modal-content">
    //                   <div className="modal-body custome-modla-body min-ht-300 d-flex align-items-center justify-content-center">
    //                     <div classNameName="modal-footer">
    //                       <img src={require("../assets/images/icon-logout.png")} className="logOut-img" alt="" />
    //                       <h5 className="mb40 gill">Are you sure you want to Logout?</h5>
    //                       <button type="button" onClick={() => this.logout()} classNameName="btn btnGreen max-WT-auto mr50" data-dismiss="modal">Yes</button>
    //                       <button type="button" onClick={() => this.setState({ modalIsOpen: false })} classNameName="btn btnGreen max-WT-auto mr50" data-dismiss="modal">No</button>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </ReactModal> */}
    //         </header>
    //         :
    //         <header classNameName="header">
    //           <div classNameName="container1440">
    //             <nav classNameName="d-flex w-100 align-items-center justify-content-between">
    //               <div classNameName="logo">
    //                 <Link to='/'>
    //                   <span>
    //                     <img
    //                       src={require("../assets/images/logo.png")}
    //                       alt="Logo"
    //                     />
    //                   </span>
    //                 </Link>
    //                 {/* <!-- <span classNameName="mob-view-logo"><img src="images/mob-logo.png" alt="Logo"/></span> --> */}
    //               </div>
    //               <div classNameName="menu " id="menubox">
    //                 <span classNameName="close-menu" id="menuClose" />
    //                 <ul classNameName="onHoverDropdown dropDownStyle">
    //                   <li classNameName="dropdown">
    //                     <Link to='/buycoindetail'>
    //                       <div>BUY BITCOINS</div>
    //                     </Link>
    //                   </li>
    //                   <li classNameName="dropdown">
    //                     <Link to='/sellbitcoindetail'>
    //                       <div >SELL BITCOINS</div>
    //                     </Link>
    //                   </li>
    //                   <li classNameName="dropdown">
    //                     <Link to='/creatadvertise'>
    //                       <div>CREATE ADVERTISIMENT</div>
    //                     </Link>
    //                   </li>

    //                   <li classNameName="dropdown">
    //                     <Link to="/faq">
    //                       <a>HELP</a>
    //                     </Link>
    //                   </li>

    //                 </ul>
    //               </div>
    //               <div classNameName="login-box">

    //                 <Link to="/login">
    //                   <div classNameName={`btn btnHeader ${this.state.headerBtnType == '/login' ? 'change-color' : ''}`}>
    //                     LOGIN
    //               </div>
    //                 </Link>
    //                 <Link to="/signup">
    //                   <div classNameName={`btn btnHeader ${this.state.headerBtnType == '/signup' ? 'change-color' : ''}`}>
    //                     SIGN UP
    //               </div>
    //                 </Link>
    //               </div>
    //               <a classNameName="mob-menu" id="menuShow">
    //                 <span classNameName="menu-bar" />
    //               </a>
    //             </nav>
    //           </div>
    //         </header >
  }
}

export default withRouter(Header);
